import React, {Component} from 'react';
import {observer} from "mobx-react";
import {fitToViewer} from "react-svg-pan-zoom";
import ReactTooltip from 'react-tooltip';

import Modal from "./Modal";
import LoadImageDialog from "./LoadImageDialog";

@observer
class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoadModal: false
        }
    }

    toggleImageLoadModal = () => {
        this.setState({
            imageLoadModal: !this.state.imageLoadModal
        });
    };

    adjustScale() {
        const {store} = this.props;
        store.setEditMode(true);
    }

    render() {
        const {store} = this.props;
        const {activeTool} = store.stage;
        const {activeLayout, hasBackgroundImage} = store.stage;

        return (
            <div className="Toolbar vt-tb">
                <button data-tip="Select/Pan (v)" data-place="right"
                        className={"ss-button sq " + (activeTool === 'pan' ? 'active' : '')}
                        onClick={() => store.stage.setTool('pan')}>
                    <i className="retina-design-0719"/>
                </button>
                <button data-tip="Draw Shape (m)" data-place="right"
                        className={"ss-button sq " + (activeTool === 'shape' ? 'active' : '')}
                        onClick={() => store.stage.setTool('shape')}>
                    {(activeLayout === 'plan') ? <i className="retina-design-0796"/> : <i className="retina-design-0763"/>}
                </button>
                <button data-tip="Zoom Extents" data-place="right" className={"ss-button sq"} onClick={() => {
                    store.stage.fitBounds(1.1);
                }}>
                    <i className="retina-arrows-0139"/>
                </button>
                <button className={"ss-button sq" + ((store.stage.showArrow === true) ? ' enabled ' : '')  + (activeLayout === 'adjacency' ? 'disabled' : '')} onClick={() => store.stage.setToggleArrow()}>
                    <i className="retina-arrows-0048"/>
                </button>
                <button data-tip="Upload Base Image" data-place="right"
                        className={"ss-button sq " + (activeLayout === 'adjacency' ? 'disabled' : '')}
                        onClick={this.toggleImageLoadModal}>
                    <i className="retina-theessentials-2576"/>
                </button>
                <button data-tip="Scale Base Image" data-place="right"
                        className={"ss-button sq " + (activeLayout === 'adjacency' || !hasBackgroundImage ? 'disabled' : '')}
                        onClick={() => store.stage.setTool('scale')}>
                    <i className="retina-design-0767"/>
                </button>



                {this.state.imageLoadModal &&
                <Modal>
                    <LoadImageDialog
                        store={store}
                        closeModal={this.toggleImageLoadModal}
                    />
                </Modal>}

                <ReactTooltip/>
            </div>
        )
    }
}

export default Toolbar;
