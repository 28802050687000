import React, {Component, forwardRef, Fragment, useImperativeHandle, useRef} from 'react';
import {inject, observer, Provider} from "mobx-react";
import ReactDataSheet from "react-datasheet";
import 'react-datasheet/lib/react-datasheet.css';
import {getRoot} from "mobx-state-tree";

@observer
class SpreadsheetPaste extends React.Component {
    constructor(props) {
        super(props);
        const numRows = 150;
        const rows = [];
        for (let i = 0; i < numRows; i++) {
            rows.push([{value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}]);
        }
        this.state = {
            grid: rows,
        };
    }

    createSpaces() {
        const {store, closeModal} = this.props;

        this.state.grid.forEach((row, i) => {
            const [name, category, quantity, size, cost, notes] = row;

            const quantityNum = parseInt(quantity.value);
            const sizeNum = parseFloat(size.value.replace(/,/g, ''));
            if (isNaN(sizeNum) || isNaN(quantityNum)) return;

            // console.log('sizeNum', sizeNum, 'quantityNum', quantityNum);


            for (let j = 0; j < quantityNum; j++) {
                const record = store.addRecord();
                record.setName(name.value);
                record.setComment(notes.value);
                const valM = store.unitsStore.fromSqUnits(sizeNum);
                record.setTargetNet(valM, true);
                if (category.value) {
                    record.updateMetadata('parentDepartment', category.value);
                }
                const costNum = parseFloat(cost.value.replace(/,/g, ''));
                if (!isNaN(costNum) && costNum > 0) {
                    record.updateMetadata('costPerSqUnit', parseFloat(cost.value.replace(/,/g, '')));
                }
            }

        });

    }

    render() {
        const {store, closeModal} = this.props;
        const columns = ['Name', 'Category', 'Quantity', 'Size', 'Cost', 'Notes'];
        return (
            <div className="SpreadsheetPaste">
                <div className="header">
                    <i className="retina-design-0809"/> Generate from Table
                </div>
                <div className="content">
                    <p>Organize your data in Excel or Google Sheets, then copy / paste it into the table below. There is
                        a limit of {this.state.grid.length} rows per import.</p>
                    <p>Only <b>Size</b> and <b>Quantity</b> are required. Note that if you enter e.g. '5' in 'quantity',
                        5 identical spaces will be generated.</p>

                    <p>There can be gaps (blanks) in the data: these will be ignored during import. You can import additional data at any point during your project.</p>

                    <div className="sheet-container">
                        <ReactDataSheet
                            data={this.state.grid}
                            sheetRenderer={props => (
                                <table className={props.className + ' my-awesome-extra-class'}>
                                    <thead>
                                    <tr>
                                        {columns.map((col, i) => (<th key={`col_${i}`}>{col}</th>))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.children}
                                    </tbody>
                                </table>
                            )}
                            valueRenderer={cell => cell.value}
                            onCellsChanged={changes => {
                                const grid = this.state.grid.map(row => [...row]);
                                changes.forEach(({cell, row, col, value}) => {
                                    grid[row][col] = {...grid[row][col], value};
                                });
                                this.setState({grid});
                            }}
                        />
                    </div>

                    <div className={"exportButtons"}>
                        <button className="cancelButton block ss-button lt-grey" onClick={closeModal}>
                            Cancel
                        </button>

                        <button className="exportButton block ss-button primary" onClick={() => {
                            this.createSpaces();
                            closeModal();
                        }}>
                            Create Rooms
                        </button>
                    </div>
                    <p>Please be patient after pressing 'Create Rooms' if generating large numbers of spaces. It can
                        take a minute or two.</p>
                </div>

            </div>
        )
    }
}

export default SpreadsheetPaste;
