import React, {Component} from 'react';
import {observer} from "mobx-react";
import Shape from "./Shape";

@observer
class PlanShape extends Component {
    constructor(props) {
        super(props);
        this.objRef = React.createRef();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.element.setSVGElement(this.objRef.current);
    }

    render() {
        const {element, store, exportMode} = this.props;
        const { presentationMode } = store.app;
        const { scale } = store.stage;

        let textAreaStyle = {
            width: element.shape.width,
            height: element.shape.height
        };
        let globalTranslateString = `translate(${-element.width / 2}, ${-element.height / 2})`;
        const fontSize = (baseVal) => {
            const logScale = Math.pow(scale, 0.8);
            return (baseVal / logScale);
        };

        const textStyle = (baseVal, wrap) => {
            const style = {fontSize: fontSize(baseVal) + 'em'};
            if (!wrap) {
                style.whiteSpace = 'nowrap';
            }
            return style;
        };
        const scaledSize = {
            width: scale * element.shape.width,
            height: scale * element.shape.height,
        };

        const breakPoints = {//calibrated
            singleRowMax: {
                small: {scale: 0.75, height: 24},
                large: {scale: 5, height: 90},
            },
            doubleRowMax: {
                small: {scale: 0.75, height: 42},
                large: {scale: 5, height: 125},
            },
            tripleRowMax: {
                small: {scale: 0.75, height: 60},
                large: {scale: 5, height: 160},
            },
            widthMax: {
                small: {scale: 0.35, width: 40},
                large: {scale: 5, width: 150},
            }
        };

        const breakPoint = (key, prop) => {
            const bp = breakPoints[key];
            //use linear interpolation between small and large breakpoints to get breakpoint 'height' for current scale
            const norm = (scale - bp.small.scale) / (bp.large.scale - bp.small.scale);
            return bp.small[prop] + norm * (bp.large[prop] - bp.small[prop]);
        };

        const nameScale = 1.2;

        const nameWrap = () => {
            return scaledSize.height > breakPoint('tripleRowMax', 'height');
        };
        const canDisplayName = () => {
            return scaledSize.height > breakPoint('doubleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };
        const canDisplayArea = () => {
            return scaledSize.height > breakPoint('singleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        const RenderShape = (props) => {
            let useForRef = props.useForRef;
            const newProps = {};
            Object.keys(props).forEach((k) => {
                if (k === 'useForRef') return;
                newProps[k] = props[k];
            });
            const {shape} = element;
            if (shape.type === 'rect') {
                return <rect ref={(useForRef) ? this.objRef : null}
                    width={shape.width}
                    height={shape.height} {...newProps}/>;
            }
            if (shape.type === 'polygon') {
                return <polygon ref={(useForRef) ? this.objRef : null}
                    points={shape.sizedVertices.map(p => p.x + ',' + p.y).join(' ')} {...newProps}/>;
            }
        };

        if (exportMode) {
            const {shape} = element;
            const textCenter = {x:shape.width/2, y: shape.height/2};
            return (
                <g className="Rect" transform={`rotate(${element.shape.rotation}) ` + globalTranslateString}>
                    {<RenderShape fill={element.fill} fillOpacity={0.9} stroke={'black'}/>}
                    <g transform={`translate(${textCenter.x}, ${textCenter.y})`}>
                        <text textAnchor="middle" fill={'#111111'} y={-10}>{this.props.element.name}</text>
                        <text textAnchor="middle" fill={'#222222'} y={10}>{store.unitsStore.displayArea(this.props.element.targetNetM)}</text>
                    </g>
                </g>
            );
        }

        return (
            <g className="Rect" transform={`rotate(${element.shape.rotation}) ` + globalTranslateString}>

                {<RenderShape fill={element.fill} fillOpacity={0.9}/>}

                {!this.props.editLabel &&
                <foreignObject
                    className="label"
                    x="5"
                    y="5"
                    width={element.width - 10}
                    height={element.height - 10}>
                    <div className="label-text">
                        <div className="label-text-inner">
                            {canDisplayName() &&
                            <div className="label-name"
                                 style={textStyle(nameScale, nameWrap())}>{element.name}</div>}
                            {canDisplayArea() &&
                            <div
                                className="label-area"
                                style={textStyle(1.3, false)}>{store.unitsStore.displayArea(element.squareM)}
                            </div>}
                        </div>
                    </div>
                </foreignObject>}

                <RenderShape
                    useForRef={true}
                    className={'object ' + ((element.isSelected) ? 'selected ' : '') + ((element.isActive) ? 'active ' : '') + ((element.hasWarnings && !presentationMode) ? 'warning ' : '')}
                    onMouseDown={this.props.handleMouseDown}
                    onDoubleClick={this.props.handleEditingName}
                    onClick={this.props.handleMouseClick}
                    onMouseOver={this.props.handleMouseOver}
                    onMouseOut={this.props.handleMouseOut}
                    fill={"transparent"}/>

                {this.props.editLabel &&
                <foreignObject
                    className="label"
                    x="5"
                    y="5"
                    width={element.width - 10}
                    height={element.height - 10}>
                    <div className="name-edit">
                            <textarea
                                autoFocus
                                style={textAreaStyle}
                                value={element.name}
                                onMouseDown={(e) => e.stopPropagation()}
                                onKeyPress={this.props.handleKeyPress}
                                onChange={(e) => this.props.editName(e.target.value)}
                            />
                    </div>
                </foreignObject>}

            </g>
        );
    }
}
PlanShape.defaultProps = {
    exportMode: false,
};
export default PlanShape;
