import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import {GlobalHotKeys} from "react-hotkeys";
import firebase from 'firebase';

import Stage from "./components/Stage/Stage";
import TabularView from "./components/Table/TabularView";
import DashboardView from "./components/Dashboard/DashboardView";

import Login from './components/Login';
import MenuBar from "./components/MenuBar";
import UndoMenu from "./components/UndoMenu";
import ViewToggle from "./components/ViewToggle";
import Toolbar from "./components/Toolbar";
import Modal from "./components/Modal";
import IntroDialog from "./components/IntroDialog";
import ProjectSettings from './components/ProjectSettings';

import {undoManager} from "./stores/RootStore";

import './App.scss';
import ExportModal from "./components/ExportModal";
import SpreadsheetPaste from "./components/Table/SpreadsheetPaste";
import CopyModal from "./components/CopyModal";
import LoadModal from "./components/LoadModal";

const TEN_SECONDS = 10 * 1000;
const TWENTY_SECONDS = 20 * 1000;


@observer
class App extends Component {
    constructor(props) {
        super(props);

        const {store, fbModel} = this.props;

        this.keyMap = {
            UNDO: ["ctrl+z", "ctrl+Z"],
            REDO: ["ctrl+shift+z", "ctrl+shift+Z"],
            CUT: ["ctrl+x", "ctrl+X"],
            PASTE: ["ctrl+v", "ctrl+V"],
            COPY: ["ctrl+c", "ctrl+C"],
            SWITCH_SELECT: ["v", "V"],
            UNITS_MODE: ["u", "U"],
            SWITCH_SHAPE: ["l", "m", "L", "M"],//match Illustrator
            DELETE: ["del", "backspace"]
        };

        this.handlers = {
            UNDO: (e) => undoManager.canUndo && undoManager.undo(),
            REDO: (e) => undoManager.canRedo && undoManager.redo(),
            PASTE: (e) => store.pasteFromClipboard(e),
            COPY: (e) => store.saveToClipboard(),
            CUT: (e) => store.cutToClipboard(),
            SWITCH_SHAPE: (e) => store.stage.setTool('shape'),
            SWITCH_SELECT: (e) => store.stage.setTool('pan'),
            DELETE: (e) => store.selectedRecords.forEach((record) => record.remove()),
            UNITS_MODE: (e) => store.unitsStore.toggleUnitsMode(),
        };
    }

    componentDidMount() {
        const {persistStore, user} = this.props.store;

        this.updateActiveUsers = setInterval(() => {
            if (!persistStore.userItem && user) {
                persistStore.addUser(user);
                persistStore.updateLastActive();
            }
        }, 2000);

        this.timeUpdateInterval = setInterval(() => persistStore.updateCurrentTime(), TEN_SECONDS);
        this.activeUserPing = setInterval(() => persistStore.updateLastActive(), TWENTY_SECONDS);
    }

    componentWillUnmount() {
        clearInterval(this.updateActiveUsers);
        clearInterval(this.timeUpdateInterval);
        clearInterval(this.activeUserPing);
    }

    toggleIntroModal = () => {
        const {app} = this.props.store;
        app.setIntroModal(!app.introModal);
    };
    toggleTabularView = () => {
        const {app} = this.props.store;
        app.setTabularView(!app.tabularView);
    };
    toggleDashboardView = () => {
        const {app} = this.props.store;
        app.setDashboardView(!app.dashboardView);
    };

    render() {
        const {store, fbModel} = this.props;
        const {app} = store;

        return (
            <div className="App">

                {!store.isLoggedIn ? (
                    <Login store={store}/>
                ) : (
                    <Fragment>
                        <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}/>
                        <Stage layout={store.stage.activeLayout} store={store}/>
                        <div className="Overlay">
                            <div className="controls-wrapper">
                                <MenuBar store={store} />
                                <UndoMenu store={store} />
                                <ViewToggle store={store} />
                            </div>

                            <Toolbar store={store} />

                            <TabularView
                                store={store}
                                fullSize={!app.dashboardView}
                                expanded={app.tabularView}
                                toggleExpand={this.toggleTabularView}
                            />
                            <DashboardView
                                store={store}
                                expanded={app.dashboardView}
                                toggleExpand={this.toggleDashboardView}
                            />
                        </div>
                        {app.introModal &&
                        <Modal class={"intro"}>
                            <IntroDialog
                                store={store}
                                fbModel={fbModel}
                                closeModal={this.toggleIntroModal}
                            />
                        </Modal>
                        }
                        {app.spreadsheetPasteModal &&
                        <Modal class={"settings-modal"}>
                            <SpreadsheetPaste
                                store={store}
                                closeModal={() => app.setSpreadsheetPasteModal(false)}
                            />
                        </Modal>
                        }
                        {app.settingsModal &&
                        <Modal class={"settings-modal"}>
                            <ProjectSettings
                                store={store}
                                closeModal={() => app.setSettingsModal(false)}
                            />
                        </Modal>
                        }
                        {app.exportModal &&
                        <Modal class={"export-modal"}>
                            <ExportModal
                                store={store}
                                closeModal={() => app.setExportModal(false)}
                                appStore={app}
                            />
                        </Modal>
                        }
                        {app.copyModal &&
                        <Modal class={"copy-modal"}>
                            <CopyModal
                                store={store}
                                closeModal={() => app.setCopyModal(false)}
                                fbModel={fbModel}
                            />
                        </Modal>
                        }
                        {app.loadModal &&
                            <Modal class={"export-modal"}>
                                <LoadModal
                                    store={store}
                                    closeModal={() => app.setLoadModal(false)}
                                    appStore={app}
                                />
                            </Modal>
                        }
                    </Fragment>
                )}
            </div>
        )
    }
}

export default App;
