import React, { Component } from 'react';
import {observer} from "mobx-react";

import { undoManager } from "../stores/RootStore";
import ReactTooltip from "react-tooltip";


@observer
class UndoMenu extends Component {
    constructor(props){
        super(props);
    }
    undo(){
        undoManager.canUndo && undoManager.undo();
    }
    redo(){
        undoManager.canRedo && undoManager.redo();
    }
    render() {
        return (
            <div className="UndoMenu">
                <div className="container">
                    <button data-tip="Undo" data-place="bottom" className={(!undoManager.canUndo ? 'disabled' : '')} onClick={this.undo}><i className={"retina-design-0737"} /></button>
                    <button data-tip="Redo" data-place="bottom" className={(!undoManager.canRedo ? 'disabled' : '')} onClick={this.redo}><i className={"retina-design-0738"} /></button>
                    <ReactTooltip />
                </div>
            </div>
        )
    }
}

export default UndoMenu;
