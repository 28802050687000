import React, {Component} from 'react';
import {observer} from "mobx-react";
import {checkForContainment, checkForOverlap, convertPts} from "../../utils";
import {Intersection, ShapeInfo} from 'kld-intersections';

@observer
class MarqueeTool extends Component {
    constructor(props) {
        super(props);
        this.marqueeToolRef = React.createRef();
        this.state = {
            width: 0,
            height: 0,
            position: {x: 0, y: 0},
            originalPosition: {x: 0, y: 0}
        };
        this.selectionRect = {y: 0, x: 0, height: 0, width: 0};
        this.elRect = null;
        this.bboxes = [];
    }

    componentDidMount = () => {
        let stage = this.props.store.stage;
        this.setState({
            position: {x: stage.mousePosition.x, y: stage.mousePosition.y},
            originalPosition: {x: stage.mousePosition.x, y: stage.mousePosition.y}
        });

        const onUp = () => {
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', onUp);
            stage.setMarqueeSelect(false);
        };

        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', onUp);
    };

    onMouseMove = (e) => {
        let store = this.props.store;
        let stage = store.stage;
        let x, y;
        x = Math.min(this.state.originalPosition.x, stage.mousePosition.x);
        y = Math.min(this.state.originalPosition.y, stage.mousePosition.y);

        if (stage.mousePosition.x - this.state.originalPosition.x < 0) {
            x = stage.mousePosition.x;
        }

        if (stage.mousePosition.y - this.state.originalPosition.y < 0) {
            y = stage.mousePosition.y;
        }

        let width = Math.abs(stage.mousePosition.x - this.state.originalPosition.x);
        let height = Math.abs(stage.mousePosition.y - this.state.originalPosition.y);

        this.setState({
            position: {x: x, y: y},
            width: width,
            height: height
        });

        if (!(this.marqueeToolRef && this.marqueeToolRef.current)) return;

        const marquee = ShapeInfo.rectangle({ left: x, top: y, width, height });
        const marqueeBBox = this.marqueeToolRef.current.getBoundingClientRect();

        const checkIntersection = (record) => {
            if (!record.SVGElement) return false;
            //fast check with bounds first
            if (!checkForOverlap(record.SVGElement.getBoundingClientRect(), marqueeBBox)) return false;
            //if fully contained, return true
            if (checkForContainment(record.SVGElement.getBoundingClientRect(), marqueeBBox)) return true;
            const poly = record.shape.type === 'circle'
                ? ShapeInfo.circle({center: record.shape.position, radius: record.shape.rx})
                : ShapeInfo.polygon(record.globalVertices);
            const intersection = Intersection.intersect(marquee, poly);

            return intersection.points.length > 0;
        };

        store.records.forEach(record => {
            if (checkIntersection(record)) {
                store.setSelected(record);
            } else {
                store.removeSelected(record);
            }
        });
    };

    render() {
        return (
            <g>
                <rect
                    ref={this.marqueeToolRef}
                    className={"marqueeTool"}
                    stroke={"black"}
                    strokeDasharray={"4"}
                    fill={"transparent"}
                    x={this.state.position.x}
                    y={this.state.position.y}
                    width={this.state.width}
                    height={this.state.height}
                />
            </g>
        );
    }
}

export default MarqueeTool;
