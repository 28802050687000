import React, {Component} from 'react';
import {observer} from "mobx-react";
import {stageToArea, stageToLinear} from "../../utils";

@observer
class Tooltip extends Component {
    render() {
        const {element, store, offsetX} = this.props;
        let position = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };
        if (element.SVGElement){
          position = element.SVGElement.getBoundingClientRect();
        }
        const w = stageToLinear(element.width);
        const h = stageToLinear(element.height);
        const units = {
            w: store.unitsStore.toLinearUnits(w),
            h: store.unitsStore.toLinearUnits(h),
        };
        return (
            <div className="Tooltip" style={{
                left: position.x + position.width +  offsetX,
                top: position.y
            }}>
              <div className="Shape__info-inner">
                  <p>
                      Area: <b>{store.unitsStore.displayArea(element.squareM)}</b>
                  </p>
                  <p>
                      Size: <b>{Math.round(units.w)} x {Math.round(units.h)}</b>
                  </p>
                  <p>
                      Category: <b>{element.parentDepartment && element.parentDepartment.name}</b>
                  </p>
              </div>
            </div>
        );

    }
}

Tooltip.defaultProps = {
    offsetX: 10,
};

export default Tooltip;
