import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import {undoManager} from "../../stores/RootStore";
import {distance, stageToLinear} from "../../utils";

@observer
class DrawCircle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            originalPosition: {x: 0, y: 0}
        };
    }

    componentDidMount = () => {
        const pos = this.props.store.stage.clickPosition;
        this.setState({
            originalPosition: {x: pos.x, y: pos.y}
        });
    };
    componentWillUnmount = () => {
        const mousePosition = this.props.store.stage.mousePosition;
        let radius = distance(this.state.originalPosition, mousePosition);
        undoManager.startGroup(() => {
            this.props.store.createRecord();
            let record = this.props.store.records[this.props.store.records.length - 1];
            record.shape.incrementPosition(this.state.originalPosition.x, this.state.originalPosition.y);
            record.shape.setRadius(radius);
            const r = stageToLinear(radius);
            let area = Math.round(Math.PI * r * r);
            record.setTargetNet(area, true);
            record.layout.markPlaced();
        });
        undoManager.stopGroup();
    };

    render() {
        const mousePosition = this.props.store.stage.mousePosition;
        let radius = distance(this.state.originalPosition, mousePosition);
        const r = stageToLinear(radius);
        const area = Math.PI * r * r;

        return (
            <Fragment>
                <g className={"drawCircle"}
                   transform={`translate(${this.state.originalPosition.x}, ${this.state.originalPosition.y})`}>
                    <circle
                        stroke={"black"}
                        strokeDasharray={"4"}
                        fill={"transparent"}
                        r={radius}
                    />
                    <text textAnchor="middle">{Math.round(area)}</text>
                </g>
            </Fragment>
        )
    }
}

export default DrawCircle;
