import {getRoot, types} from "mobx-state-tree";
import {Record} from "../stores/RecordStore";

export const Arrow = types
    .model("Arrow", {
        id: types.identifier,
        target: types.maybeNull(types.reference(Record)),
        origin: types.maybeNull(types.reference(Record))
    })
    .views(self => ({
        get isSelected() {
            let selection = getRoot(self).arrowSelection;
            if (!selection) return false;
            return selection.indexOf(self) > -1
        },
    }))
    .actions(self => ({
        beforeDestroy(){
            if (getRoot(self).arrowSelection.indexOf(self) > -1){
                getRoot(self).toggleArrowSelected(self);
            }
        },
        setTarget(target) {
            self.target = target;
        },
        setOrigin(origin) {
            self.origin = origin;
        },
    }));
