import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import {getSnapshot} from 'mobx-state-tree';
import {observer} from "mobx-react";
import {saveAs} from 'file-saver';

import App from "../App";
import store from "../stores/RootStore";
import Stage from "./Stage/Stage";

import logo from "../assets/256x256.png";

const PRGM = 'PRGM';
const CSV = 'CSV';
const SVG = 'SVG';


@observer
class CopyModal extends Component {

    captureFormatChange = (val) => {
        const {store, closeModal, appStore} = this.props;
        appStore.setExportModalState(val);
    };

    getFormatTitle = () => {
        return (<div>
            <div className="title-text">
                CHOOSE YOUR DOWNLOAD FILE FORMAT
            </div>
        </div>);
    };


    makeCopy() {
        const {store, fbModel, closeModal} = this.props;
        fbModel.getFBData((fbData) => {
            fbModel.duplicate(store.persistStore.fileId, store.copyFileName, fbData, store.persistStore, store.persistStore.user, () => {
                store.setCopyMode(false);
                closeModal();
            });
        })


    }

    render() {
        const {store, fbModel, closeModal} = this.props;

        return (
            <div className="CopyModal">
                <div className="header">
                    <i className="retina-theessentials-2550"/> Copy Document
                </div>
                <p><i>
                    Make a copy.
                </i></p>
                <div className="copybox">
                    <div className="copytext">
                        <input placeholder="copy of.." data-place="bottom" value={store.copyFileName}
                               onChange={e => store.setCopyFileName(e.target.value)}/>
                    </div>
                    <div className="loading_icon">
                        {store.copyMode &&
                        <i className="retina-theessentials-2632 icon-spinning" title="copy in progress..."/>}
                    </div>
                </div>

                <div className="copyButtons">
                    <button className="cancelButton block ss-button lt-grey" onClick={closeModal}>
                        Cancel
                    </button>

                    <button
                        className={store.copyMode ? "copyButton block ss-button primary disabled" : "copyButton block ss-button primary"}
                        onClick={() => {
                            store.setCopyMode(true);
                            this.makeCopy();
                        }}>
                        Copy
                    </button>
                </div>
            </div>
        );
    }
}

export default CopyModal;