import React from 'react';
import { observer } from 'mobx-react';

import Bar from './Bar';


function StackedBar({ label, value, values, total, format, ramp }) {
    if (!Array.isArray(values)) {
        values = [values];
    }

    if (typeof format !== 'function') {
        format = x => x;
    }

    return (
        <div className="BarChart StackedBar">
            <label>{label}</label>

            <div className="BarChart__graphic">
                <Bar values={values} total={total} ramp={ramp} />
           </div>

            <div className="StackedBar__value">
                {format(value !== undefined ? value : values.reduce((a,b) => a + b, 0))}
            </div>
        </div>
    );
}


export default observer(StackedBar);
