import React, { Component } from 'react';
import {observer} from "mobx-react";

import Modal from './Modal';
import { distance, stageToLinear } from '../utils';


@observer
class ScaleBackgroundDialog extends Component {

    constructor() {
        super(arguments);

        this.state = {
            givenLength: null,
        };
    }

    onSave() {
        const { store, closeModal } = this.props;
        const [p1, p2] = store.stage.toolData;
        const { givenLength } = this.state;

        if (givenLength !== null) {
            const m = stageToLinear(distance(p1, p2));
            store.stage.setImageScale(givenLength / store.unitsStore.toLinearUnits(m));//store.unitsStore.toLinearUnits(m)
        }

        closeModal();
    }

    render() {
        const { store } = this.props;

        return (
            <Modal>
                <div className="ScaleBackgroundDialog">
                    <h3>Scale Background Image</h3>

                    <p className="p-md">
                        <label className="block nl" htmlFor="imageSegmentLength">
                            Segment Length
                        </label>

                        <input
                            type="number"
                            name="imageSegmentLength"
                            autoFocus="true"
                            min="0"
                            value={this.state.actualLength}
                            placeholder={"0"}
                            onChange={e => this.setState({ givenLength: e.target.value })}
                        />
                        {store.unitsStore.linearUnitsDisplay}
                    </p>

                    <div className="buttons">
                        <button className="ss-button nl" onClick={this.props.closeModal}>Cancel</button>
                        <button className="ss-button primary" onClick={() => this.onSave()}>Save</button>
                    </div>
                </div>
            </Modal>
        );
    }


}


export default ScaleBackgroundDialog;
