import React, { Component } from 'react';
import {observer} from "mobx-react";

@observer
class ViewToggle extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const {store} = this.props;
        return (
            <div className="ViewToggle">
                <button className={(store.stage.activeLayout === 'adjacency') ? 'active' : ''} onClick={() => store.stage.setActiveLayout("adjacency")}>
                    <p className="p-md">Adjacencies</p>
                </button>
                <button className={(store.stage.activeLayout === 'plan') ? 'active' : ''} onClick={() => store.stage.setActiveLayout("plan")}>
                    <p className="p-md">Plan</p>
                </button>
            </div>
        )
    }
}

export default ViewToggle;
