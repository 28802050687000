import React from 'react';
import { observer } from 'mobx-react';
import firebase from 'firebase';
import logo from '../assets/256x256.png';


function Login({ store }) {
    const provider = new firebase.auth.GoogleAuthProvider();

    const handleSignIn = () => {
        firebase.auth().signInWithPopup(provider);
    };

    return (
        <div className="Login">
            <div>
                <img src={logo} alt="Programming Tool" />
                <h1>Programming Tool</h1>

                <p>please login with your Sasaki account</p>

                <button
                    type="button"
                    className="ss-button primary"
                    onClick={handleSignIn}
                >
                    Login with Google
                </button>
            </div>
        </div>
    );
}


export default observer(Login);
