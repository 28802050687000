export function randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function convertPts(coords) {
    let svg = document.getElementsByClassName('stage-svg')[0].getElementsByTagName("svg")[0];
    let pt = svg.createSVGPoint();
    pt.x = coords.x;
    pt.y = coords.y;
    return pt.matrixTransform(svg.getElementById('scale-wrapper').getScreenCTM().inverse());
}

export function inflateRect(rect, factor) {
    const w = rect.width * factor;
    const h = rect.height * factor;
    return {
        x: rect.x + (rect.width - w) / 2,
        y: rect.y + (rect.height - h) / 2,
        width: w,
        height: h,
    }
}

export function combineBBoxes(arrayOfBBoxes) {
    let combinedBB = {
        x1: Infinity,
        y1: Infinity,
        x2: -Infinity,
        y2: -Infinity
    };
    arrayOfBBoxes.forEach(bb => {
        combinedBB.x1 = Math.min(bb.x, combinedBB.x1);
        combinedBB.y1 = Math.min(bb.y, combinedBB.y1);
        combinedBB.x2 = Math.max(bb.x + bb.width, combinedBB.x2);
        combinedBB.y2 = Math.max(bb.y + bb.height, combinedBB.y2);
    });
    return {
        x: combinedBB.x1,
        y: combinedBB.y1,
        width: combinedBB.x2 - combinedBB.x1,
        height: combinedBB.y2 - combinedBB.y1,
    }
}

export function rectToPoints(rect) {
    return {
        x1: rect.x,
        y1: rect.y,
        x2: rect.x + rect.width,
        y2: rect.y + rect.height,
    };
}

export function checkForContainment(RectA, RectB) {
    const A = rectToPoints(RectA);
    const B = rectToPoints(RectB);

    // console.log(A, B);

    return (
        A.x1 > B.x1
        && A.x2 < B.x2
        && A.y1 > B.y1
        && A.y2 < B.y2
    );
}

export function checkForOverlap(RectA, RectB) {
    const A = rectToPoints(RectA);
    const B = rectToPoints(RectB);

    // console.log(A, B, A.x2 >= B.x1, B.x2 >= A.x1, A.y2 >= B.y1, B.y2 >= A.y1);
    return (
        (A.x2 >= B.x1 && B.x2 >= A.x1)
        &&
        (A.y2 >= B.y1 && B.y2 >= A.y1)
    );
}

// https://github.com/mockingbot/react-resizable-rotatable-draggable/blob/master/src/utils.js
export const getLength = (x, y) => Math.sqrt(x * x + y * y);
export const degToRadian = (deg) => deg * Math.PI / 180;
export const cos = (deg) => Math.cos(degToRadian(deg));
export const sin = (deg) => Math.sin(degToRadian(deg));
export const setWidthAndDeltaW = (width, deltaW, minWidth) => {
    const expectedWidth = width + deltaW;
    if (expectedWidth > minWidth) {
        width = expectedWidth
    } else {
        deltaW = minWidth - width;
        width = minWidth
    }
    return {width, deltaW}
};
export const setHeightAndDeltaH = (height, deltaH, minHeight) => {
    const expectedHeight = height + deltaH;
    if (expectedHeight > minHeight) {
        height = expectedHeight
    } else {
        deltaH = minHeight - height;
        height = minHeight
    }
    return {height, deltaH}
};
export const boundingBox = (degrees, x, y, w, h) => {
    //https://stackoverflow.com/questions/10392658/calculate-the-bounding-boxs-x-y-height-and-width-of-a-rotated-element-via-jav
    let points = [
        {x: x, y: y},
        {x: x + w, y: y},
        {x: x + w, y: y + h},
        {x: x, y: y + h},
    ];
    let minX = Math.min(...points.map(point => point.x));
    let minY = Math.min(...points.map(point => point.y));
    let maxX = Math.max(...points.map(point => point.x));
    let maxY = Math.max(...points.map(point => point.y));
    let pivot = {
        x: maxX - ((maxX - minX) / 2),
        y: maxY - ((maxY - minY) / 2)
    };
    let radians = degrees * (Math.PI / 180);
    let cos = Math.cos(radians);
    let sin = Math.sin(radians);

    function rotatePoint(pivot, point, cos, sin) {
        return {
            x: (cos * (point.x - pivot.x)) - (sin * (point.y - pivot.y)) + pivot.x,
            y: (sin * (point.x - pivot.x)) + (cos * (point.y - pivot.y)) + pivot.y
        };
    }

    let bb = {
        x1: Number.POSITIVE_INFINITY,
        y1: Number.POSITIVE_INFINITY,
        x2: Number.NEGATIVE_INFINITY,
        y2: Number.NEGATIVE_INFINITY,
    };

    points.forEach((point) => {
        let rotatedPoint = rotatePoint(pivot, point, cos, sin);

        bb.x1 = Math.min(bb.x1, rotatedPoint.x);
        bb.y1 = Math.min(bb.y1, rotatedPoint.y);
        bb.x2 = Math.max(bb.x2, rotatedPoint.x);
        bb.y2 = Math.max(bb.y2, rotatedPoint.y);
    });

    return {x: bb.x1, y: bb.y1, width: bb.x2 - bb.x1, height: bb.y2 - bb.y1};
};
export const distance = (p1, p2) => {
    const a = p1.x - p2.x;
    const b = p1.y - p2.y;

    return Math.sqrt(a * a + b * b);
};

// point - { x, y }
// line - { s: {x, y}, e: {x, y} }
export const distToSegment = (point, line) => {
    const dx = line.e.x - line.s.x;
    const dy = line.e.y - line.s.y;
    const l2 = dx * dx + dy * dy;

    if (l2 === 0)
        return distance(point, {x: line.s.x, y: line.s.y});

    let t = ((point.x - line.s.x) * dx + (point.y - line.s.y) * dy) / l2;
    t = Math.max(0, Math.min(1, t));

    return distance(point, {x: line.s.x + t * dx, y: line.s.y + t * dy});
};

export const guessAtRounding = (gross) => {
    if (gross < 100) {
        return Math.round(gross);
    } else if (gross < 500) {
        return 10 * Math.round(gross / 10);
    } else {
        return 25 * Math.round(gross / 25);
    }
};
export const stageToLinear = (units) => {
    return units / PLAN_FT_RATIO;
};
export const linearToStage = (units) => {
    return units * PLAN_FT_RATIO;
};
export const stageToArea = (units) => {
    return units / PLAN_SQ_FT_RATIO;
};
export const areaToStage = (units) => {
    return units * PLAN_SQ_FT_RATIO;
};
export const PLAN_SQ_FT_RATIO = 54;
export const PLAN_FT_RATIO = Math.sqrt(PLAN_SQ_FT_RATIO);

