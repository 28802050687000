import {observable} from 'mobx';
import {getRoot, types} from "mobx-state-tree";
import React from "react";

export const AppStore = types
    .model("AppStore", {
        introModal: types.optional(types.boolean, true),
        settingsModal: types.optional(types.boolean, false),
        spreadsheetPasteModal: types.optional(types.boolean, false),
        loadModal: types.optional(types.boolean, false),
        exportModal: types.optional(types.boolean, false),
        copyModal: types.optional(types.boolean, false),
        exportModalState: types.optional(types.string, "CSV"),
        tabularView: types.optional(types.boolean, true),
        dashboardView: types.optional(types.boolean, true),
        clientWidth: types.optional(types.number, 1000),
        clientHeight: types.optional(types.number, 1000),
        writeInProgress: types.optional(types.boolean, false),
        presentationMode: types.optional(types.boolean, false),
    })
    .views(self => ({
        get tableUpdatesDisabled() {
            return self.spreadsheetPasteModal || !self.tabularView;
        },
        get viewableArea() {
            //from Overlay CSS
            const toolBarWidth = 56;
            const toolBarHeight = 16 + 39 + 10;
            const dashboardWidth = 40 + 516;

            const tableHeight = 22 + (self.clientHeight - toolBarHeight) / 2;

            const visibleHeight = self.clientHeight - toolBarHeight - (self.tabularView ? tableHeight : 0);
            const visibleWidth = (self.clientWidth - toolBarWidth - (self.dashboardView ? dashboardWidth : 0));

            const bottomSpace = self.tabularView ? tableHeight : 0;
            const rightSpace = self.dashboardView ? dashboardWidth : 0;
            return {
                top: toolBarHeight,
                left: toolBarWidth,
                right: rightSpace,
                bottom: bottomSpace,
                width: visibleWidth,
                height: visibleHeight
            }
        },
    }))
    .actions(self => ({
        afterAttach() {
            const updateClientSize = () => {
                self.setClientSize(document.documentElement.clientWidth, document.documentElement.clientHeight);
            };
            window.addEventListener("resize", updateClientSize);
            updateClientSize();
        },
        setIntroModal(introModal) {
            self.introModal = introModal
        },
        setSpreadsheetPasteModal(spreadsheetPasteModal = false) {
            self.spreadsheetPasteModal = spreadsheetPasteModal;
        },
        setSettingsModal(settingsModal = false) {
            self.settingsModal = settingsModal;
        },
        setLoadModal(loadModal = false){
            self.loadModal = loadModal;
        },
        setExportModal(exportModal = false) {
            self.exportModal = exportModal;
        },
        setExportModalState(exportModalState) {
            self.exportModalState = exportModalState;
        },
        setCopyModal(copyModal = false) {
            self.copyModal = copyModal;
        },
        setTabularView(tabularView) {
            self.tabularView = tabularView
        },
        setDashboardView(dashboardView) {
            self.dashboardView = dashboardView
        },
        setClientSize(width, height) {
            self.clientWidth = width;
            self.clientHeight = height;
        },
        setWriteInProgress(bool) {
            self.writeInProgress = bool;
        },
        setPresentationMode(bool) {
            self.presentationMode = bool;
        }
    }));
