import {applySnapshot, clone, destroy, getParent, getSnapshot, types} from "mobx-state-tree";
import {UndoManager} from "mst-middlewares"
import {v4} from "node-uuid";
import md5 from 'crypto-js/md5';
import firebase from 'firebase';

import {Record} from "./RecordStore";
import {StageStore} from "./StageStore";
import {combineBBoxes, inflateRect, randomString} from "../utils";
import {UnitsStore} from "./UnitsStore";
import React from "react";
import {AppStore} from "./AppStore";
import {PersistStore} from "./PersistStore";
import {Arrow} from "../models/Arrow";


export let undoManager = {};
export const setUndoManager = targetStore => {
    undoManager = UndoManager.create({}, {targetStore})
};

export const RootStore = types
    .model("RootStore", {
        selection: types.array(types.reference(Record)),
        arrowSelection: types.array(types.reference(Arrow)),
        stage: types.optional(StageStore, {}),
        app: types.optional(AppStore, {}),
        unitsStore: types.optional(UnitsStore, {}),
        persistStore: types.optional(PersistStore, {}),
        copyFileName: types.optional(types.string, 'Copy of '),
    })
    .volatile(self => ({
        clipboard: [],
        filterBy: '',
        gridApi: '',
        user: null,
        fbModel: null,
        copyMode: false,
    }))
    .views(self => ({
        get records() {
            return self.persistStore.records;
        },
        get arrows() {
            return self.persistStore.arrows;
        },
        get project() {
            return self.persistStore.project;
        },
        get activeRecords() {
            return self.records.filter(record => record.isActive);
        },
        get unplacedRecords() {
            return self.records.filter(record => !record.layout.isPlaced);
        },
        get totalTargetSquareUnits() {
            return self.records.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.targetGrossUnits;
            }, 0);
        },
        get totalActualSquareUnits() {
            return self.records.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.squareUnits;
            }, 0);
        },
        get totalNetSquareUnits() {
            return self.records.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.targetNetUnits;
            }, 0);
        },
        get totalCost() {
            return self.records.reduce((a, b) => a + b.cost, 0);
        },
        get selectedRecords() {
            return self.selection.slice().sort((a, b) => b.perimeter - a.perimeter);
        },
        get unselectedRecords() {
            const unSelected = self.records.filter((record) => !record.isSelected);
            return unSelected.sort((a, b) => b.perimeter - a.perimeter);
        },
        get sortedRecords() {
            return self.records.slice().sort((a, b) => b.perimeter - a.perimeter);
        },
        get editingText() {
            return (self.selection.filter((record) => record.editingText || record.editingName).length > 0);
        },
        get isFiltering() {
            return self.filterBy !== '';
        },
        get isLoggedIn() {
            return self.user !== null;
        },
        get departments() {
            return self.persistStore.parentDepartments;
        },
        get fileHash() {
            return self.fileId;
        },
        get activeBounds() {
            let bboxes = [];
            self.records.forEach((record, i) => {
                bboxes.push(record.shape.boundingBox);
            });
            if (bboxes.length === 0) {
                return {x: 0, y: 0, width: 0, height: 0}
            }
            return combineBBoxes(bboxes);
        },
        getRecordByID(id) {
            return self.records.filter(record => record.id === id);
        },
        uniqueTableValues(colId) {
            const uniques = [];

            self.records.forEach((record) => {
                let value = (record[colId] && record[colId].name ? record[colId].name : record[colId]);
                if (value && uniques.indexOf(value) === -1) {
                    uniques.push(value);
                }
            });
            return uniques;
        },
        /*getcopyFileName(){
            return "Copy of " + self.persistStore.fileName;
        },*/
    }))
    .actions(self => ({
        setFbModel(model) {
            self.fbModel = model;
        },
        afterCreate() {
            setUndoManager(self);
        },
        addRecord() {
            const record = Record.create({
                id: v4()
            });
            self.records.push(record);
            return record;
        },
        copyRecord(record) {
            const newRecord = Record.create({
                ...getSnapshot(record),
                id: v4()
            });
            self.records.push(newRecord);
            return newRecord;
        },
        addArrow(originRef, targetRef,) {
            self.arrows.push({
                id: v4(),
                target: targetRef,
                origin: originRef,
            });
        },
        createRecord() {
            return self.addRecord();
        },
        clearSelected() {
            self.selectedRecords.forEach(record => {
                record.setEditingText(false);
            });
            self.selection = [];
        },
        setUser(user = null) {
            self.user = user;
        },
        setSelected(selected) {
            if (self.selection.indexOf(selected) === -1) {
                self.selection.push(selected);
            }
        },
        removeSelected(record) {
            let idx = self.selection.indexOf(record);
            if (idx > -1) {
                self.selection.splice(idx, 1);
            }
        },
        removeRecord(record) {
            self.removeSelected(record);
            self.arrows.forEach(arrow => {
                if (arrow.target === record || arrow.origin === record) {
                    self.removeArrow(arrow);
                }
            });
            destroy(record)
        },
        toggleArrowSelected(selectedArrow) {
            let idx = self.arrowSelection.indexOf(selectedArrow);
            if (idx === -1) {
                self.arrowSelection.push(selectedArrow);
            } else {
                self.arrowSelection.splice(idx, 1);
            }
        },
        removeArrow(arrow) {
            let idx = self.arrowSelection.indexOf(arrow);
            if (idx > -1) {
                self.arrowSelection.splice(idx, 1);
            }
            destroy(arrow);
        },
        saveToClipboard() {
            self.clipboard = self.selection.slice();
        },
        cutToClipboard() {
            self.clipboard = self.selection.slice();
            self.selection.forEach(record => self.removeRecord(record));
        },
        getRandomArbitrary(min, max) {
            return Math.random() * (max - min) + min;
        },
        pasteFromClipboard(e) {
            if (self.app && self.app.spreadsheetPasteModal) {//don't override default paste if spreadsheet paste modal is open
                return;
            }
            if (self.clipboard.length > 0) {
                e.preventDefault();

                let bboxes = [];
                let newRecords = [];
                let stage = self.stage;

                // two loops are used to account for copying, then deleting the original record, then pasting
                self.clipboard.forEach(record => {
                    const newRecord = self.copyRecord(record);
                    bboxes.push(newRecord.shape.boundingBox);
                    newRecords.push(newRecord);
                });
                let combinedBBox = combineBBoxes(bboxes);

                self.clearSelected();

                const offset = {
                    x: stage.mousePosition.x - combinedBBox.x,
                    y: stage.mousePosition.y - combinedBBox.y
                };

                newRecords.forEach(record => {
                    record.layout.incrementPosition(offset.x, offset.y);
                    record.setEditingName(false);
                    self.setSelected(record);
                });
            }
        },
        setFilterBy(filterBy = '') {
            self.filterBy = filterBy;
        },
        setGridApi(gridApi) {
            self.gridApi = gridApi;
        },
        setCopyFileName(name) {
            self.copyFileName = name;
        },
        setCopyMode(mode) {
            self.copyMode = mode;
        },
    }));

const store = RootStore.create({
    records: [],
    stage: {
        activeLayout: "adjacency"
    }
});
// const store = RootStore.create({
//     records: [
//         {
//             id: "22ebd31f-df81-4622-a565-c52baac9ae3f",
//             name: "Name1",
//             department: "Men's Reception",
//             parentDepartment: "Men's",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 15,
//                         position: {
//                             x: 50,
//                             y: 100
//                         },
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 50,
//                         height: 75,
//                         position: {
//                             x: 50,
//                             y: 100
//                         }
//                     }
//                 },
//             }
//         },{
//             id: "7d8c5522-e7c3-43e4-864a-0e9176932894",
//             name: "Name2",
//             department: "Men's Team Locker Room",
//             parentDepartment: "Men's",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 25,
//                         position: {
//                             x: 100,
//                             y: 400
//                         }
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 100,
//                         height: 75,
//                         position: {
//                             x: 300,
//                             y: 400
//                         }
//                     }
//                 }
//             }
//         },{
//             id: "c14b14bb-c078-4794-82ec-099bffbc2795",
//             name: "Name3",
//             department: "Women's Reception",
//             parentDepartment: "Women's",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 40,
//                         position: {
//                             x: 50,
//                             y: 100
//                         }
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 100,
//                         height: 75,
//                         position: {
//                             x: 550,
//                             y: 100
//                         }
//                     }
//                 }
//             }
//         },{
//             id: "79c602c4-e55c-4baf-a753-096637cc2c6e",
//             name: "Name4",
//             department: "Women's Offices",
//             parentDepartment: "Women's",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 25,
//                         position: {
//                             x: 98,
//                             y: 500
//                         }
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 25,
//                         height: 25,
//                         position: {
//                             x: 450,
//                             y: 300
//                         }
//                     }
//                 },
//             }
//         },{
//             id: "c954adb8-7b8f-49d9-b5c9-37448b1e308b",
//             name: "Name5",
//             department: "Women's Film Room",
//             parentDepartment: "Women's",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 39,
//                         position: {
//                             x: 100,
//                             y: 100
//                         }
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 25,
//                         height: 75,
//                         position: {
//                             x: 270,
//                             y: 370
//                         }
//                     }
//                 }
//             }
//         }, {
//             id: "51ae0bb6-c8dd-4c86-a15a-3e0cca8feee7",
//             name: "Name6",
//             department: "Shared Practice Gymnasium",
//             parentDepartment: "Shared by Teams",
//             layouts: {
//                 "adjacency": {
//                     id: "adjacency",
//                     name: "adjacency",
//                     shape: {
//                         type: "circle",
//                         rx: 10,
//                         position: {
//                             x: 500,
//                             y: 100
//                         }
//                     }
//                 },
//                 "plan": {
//                     id: "plan",
//                     name: "plan",
//                     shape: {
//                         type: "rect",
//                         width: 100,
//                         height: 75,
//                         position: {
//                             x: 425,
//                             y: 100
//                         }
//                     }
//                 }
//             }
//         }
//     ],
//     stage: {
//         activeLayout: "plan",
//         imageURL: ""
//     }
// });

export default store;
