import firebase from 'firebase';
import Jimp from 'jimp';
import md5 from 'md5';

function repath(value) {
    value = value.replace('https://firebasestorage.googleapis.com/v0/b/programming-tool-584cc.appspot.com/o/images%2F', '');
    return `https://storage.googleapis.com/programming-tool-584cc.appspot.com/images/${value.split('?alt=media&token=')[0]}`;
}

export default class ImageHandler {

    constructor(appState) {
        this.appState = appState;
        this.storageRef = firebase.storage().ref();
    }

    async processImageAsync(buffer, fileType) {
        const arr = new Uint8Array(buffer);

        const hash = md5(arr);

        const ref = this.storageRef.child(`images/${hash}.${fileType}`);

        const mimetype = fileType === 'png' ? Jimp.MIME_PNG : Jimp.MIME_JPEG;

        const sizes = {
            'large': 1600,
            'medium': 1200,
            'small': 600,
        }

        let url;

        try {//TODO store metadata on images so that if the image already exists, we can know image dimensions etc
            url = repath(await ref.getDownloadURL());// already exists...
        } catch (e) {
            const putOptions = {
                contentType: mimetype,
            };
            await ref.put(buffer, putOptions);
            const image = await Jimp.read(buffer);

            const refSize = this.storageRef.child(`images/${hash}.${fileType}`);
            await refSize.put(await image.getBufferAsync(mimetype), putOptions);
            url = repath(await refSize.getDownloadURL());
        }
        return url;
    }

    processImage(dataUrl, fileType) {
        if (dataUrl instanceof ArrayBuffer) {
            // const buffer = new Uint8Array(dataUrl);
            const buffer = dataUrl;
            return this.processImageAsync(buffer, fileType);
        }
        throw new Error("DATA URL must be a buffer")
    }

}
