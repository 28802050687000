import React from 'react';
import {getRoot, types} from 'mobx-state-tree';

import {SQM_TO_SQFT, UnitsStore} from './UnitsStore';

const toNum = x => parseFloat(x || 0);

export const UNITS = {
    IMPERIAL: {
        unit: 'feet',
    },
    METRIC: {
        unit: 'meters',
    },
};

const __defaultUnit = 'IMPERIAL';

const goalSeek = (target, getter, setter, tol = 0.1, maxIterations = 1000) => {
    let found = false;
    for (let i = 0; i < maxIterations; i++) {
        if (Math.abs(getter() - target) < tol) {
            // console.log(`Goal seek resolved after ${i} iterations`);
            found = true;
            break;
        }
        const change = target / getter();
        setter(change);
        // console.log(i, 'changer', getter(), change);
    }
    return found;
};

export default types.model('ProjectStore', {
    m_targetGrossArea: 250000 / SQM_TO_SQFT,
    escalationRate: 3.5,//(r) as perc
    escalationTime: 3,//(t) time in months
    grossingFactor: 75,
    otherCosts: 5000000,
    softCostsPerc: 5,
    totalProjectBudget: 100000000,
    unitSystem: types.optional(types.enumeration('unit', Object.keys(UNITS)), __defaultUnit),
})
    .actions(self => ({

        setEscalationCost(cost = 0) {
            const before = self.escalationRate;
            if (!goalSeek(cost, () => self.escalationCost, (change) => {
                self.escalationRate = (self.escalationRate * change)
            })) {
                self.escalationRate = before;
            }
        },

        setCostPerUnit(costPerUnit = 0) {
            const before = self.totalProjectBudget;
            if (!goalSeek(costPerUnit, () => self.costPerUnit, (change) => {
                self.totalProjectBudget = (self.totalProjectBudget * change)
            })) {
                self.totalProjectBudget = before;
            }
        },

        setProgramBudget(programBudget = 0) {
            const before = self.totalProjectBudget;
            if (!goalSeek(programBudget, () => self.programBudget, (change) => {
                self.totalProjectBudget = (self.totalProjectBudget * change)
            })) {
                self.totalProjectBudget = before;
            }
        },

        setConstructionBudget(constructionBudget = 0) {
            const before = self.totalProjectBudget;
            if (!goalSeek(constructionBudget, () => self.constructionBudget, (change) => {
                self.totalProjectBudget = (self.totalProjectBudget * change)
            })) {
                self.totalProjectBudget = before;
            }
        },

        setEscalationPerc(perc = 0) {
            const before = self.escalationRate;
            if (!goalSeek(perc, () => self.escalationPerc, (change) => {
                self.escalationRate = (self.escalationRate * change)
            }, 0.001)) {
                self.escalationRate = before;
            }
        },

        setEscalationRate(perc = 0) {
            self.escalationRate = toNum(perc);
        },

        setEscalationTime(time = 0) {
            self.escalationTime = toNum(time);
        },

        setOtherCosts(costs = 0) {
            self.otherCosts = toNum(costs);
        },

        setSoftCostsPerc(perc = 0) {
            self.softCostsPerc = toNum(perc);
        },

        setSoftCost(softCost = 0) {
            const before = self.softCostsPerc;
            //NOTE: programBudget includes softCostsPerc, so this doesn't add up but it gets us close enough for goal seek
            self.softCostsPerc = 100 * toNum(softCost) / self.totalAfterEscalation;

            if (!goalSeek(softCost, () => self.softCost, (change) => {
                self.softCostsPerc = (self.softCostsPerc * change)
            })) {
                self.softCostsPerc = before;
            }
        },

        setGrossingFactor(factor = 0) {
            self.grossingFactor = toNum(factor);
        },

        setTargetGrossArea(area = 0) {
            self.m_targetGrossArea = self.invert(toNum(area));
        },

        setTargetNetArea(area = 0) {
            self.setTargetGrossArea(area / (self.grossingFactor / 100));
        },

        setTotalProjectBudget(budget = 0) {
            self.totalProjectBudget = toNum(budget);
        },

        setUnitSystem(system = __defaultUnit) {
            self.root.unitsStore.setUnitsMode(UNITS[system].unit);

            self.unitSystem = system;
        },

    }))
    .views(self => ({

        get root() {
            return getRoot(self);
        },

        convert(x) {
            return self.root.unitsStore.toSqUnits(x);//NOTE: cannot use rounding here - otherwise it messes up calculations
        },

        invert(x) {
            return self.root.unitsStore.fromSqUnits(x);
        },

        get constructionBudget() {
            return self.softCost + self.programBudget;
        },

        get totalAfterEscalation() {
            return self.escalationCost + self.otherCosts + self.programBudget;
        },

        get costPerUnit() {
            return self.programBudget / (self.targetGrossArea || 1);
        },

        get escalationCost() {
            // console.log('get escalationCost', self.otherCosts, self.programBudget, self.escalationPerc, (self.otherCosts + self.programBudget) * (self.escalationPerc / 100));
            return (self.otherCosts + self.programBudget) * (self.escalationPerc / 100);
        },

        get netArea() {
            return self.targetGrossArea * (self.grossingFactor / 100);
        },

        get programBudget() {
            const w = self.softCostsPerc / 100;
            const f = self.escalationPerc / 100;
            const q = w * f + w + f + 1;

            return (self.totalProjectBudget - (self.otherCosts * q)) / (q || 1);
        },

        get softCost() {
            return self.totalAfterEscalation * (self.softCostsPerc / 100);
        },

        get targetGrossArea() {
            return self.convert(self.m_targetGrossArea);
        },

        get escalationPerc() {//c = (1+r)^t
            const r = self.escalationRate / 100;
            return 100 * Math.pow(1 + r, (self.escalationTime / 12)) - 100;
        },
    }))
;
