import React, { Component } from 'react';
import {observer} from "mobx-react";

import Shape from "./Shape";
import DrawRect from "./DrawRect";
import SelectionBox from "./SelectionBox";
import MarqueeTool from "./MarqueeTool";
import Arrow from "./Arrow";
import ScaleBackgroundDialog from '../ScaleBackgroundDialog';

@observer
class PlanView extends Component {

    componentDidMount = () => {
        this.props.store.unplacedRecords.map((record, i) => {
            record.layout.shape.resetPosition();
            record.layout.incrementPosition(i * 50 + i * 10, 0)
        });
    };

    render() {
        const {store, exportMode} = this.props;
        const {stage} = store;
        let imageW = 0;
        let imageH = 0;
        if (stage.imageSize && stage.imageSize.length === 2)  {
            imageW = stage.imageSize[0] * stage.imageScale;
            imageH = stage.imageSize[1] * stage.imageScale;
        }

        if (exportMode)  {
            //TODO support image background
            return <g>
                {store.sortedRecords.map((el) =>
                    <Shape
                        store={store}
                        exportMode={exportMode}
                        key={el.id}
                        element={el}
                    />
                )}
                {store.stage.showArrow && store.arrows.map((el) =>
                    <Arrow
                        store={store}
                        exportMode={exportMode}
                        key={`${el.id}${String(stage.scale)}`}
                        element={el}
                    />
                )}
            </g>
        }

        return(
                <g>
                    {stage.imageURL &&
                        <g transform={`translate(${-imageW / 2} ${-imageH / 2}) scale(${stage.imageScale})`}>
                            <image href={stage.imageURL}
                                   x="500"
                                   y="300"
                            />
                        </g>
                    }

                    {stage.activeTool === 'scale' && stage.toolData.map(({ x, y }) => (
                        <circle
                            key={String(x) + String(y)}
                            cx={x}
                            cy={y}
                            r={5}
                            fill={'red'}
                        />
                    ))}

                    {stage.hasScaleCoords && (
                        <ScaleBackgroundDialog
                            closeModal={() => stage.setTool('pan')}
                            store={store}
                        />
                    )}

                    {store.sortedRecords.map((el) =>
                        <Shape
                            store={store}
                            key={el.id}
                            element={el}
                        />
                    )}

                    {store.stage.showArrow && store.arrows.map((el) =>
                        <Arrow
                            store={store}
                            key={`${el.id}${String(stage.scale)}`}
                            element={el}
                        />
                    )}

                    {store.selection.length && !stage.dragging && !stage.resizing &&
                        <SelectionBox store={store} />
                    }

                    {stage.activeTool === 'shape' && stage.drawing &&
                        <DrawRect store={store} />
                    }

                    {stage.marqueeSelect &&
                        <MarqueeTool store={store} />
                    }
                </g>
        )
    }
}
PlanView.defaultProps = {
    exportMode: false,
};
export default PlanView;
