import {getRoot, types, destroy} from "mobx-state-tree";
import {compose, fromDefinition, toString} from 'transformation-matrix';

import {Layout} from "./LayoutStore";
import {values} from "mobx";
import {linearToStage, PLAN_SQ_FT_RATIO, stageToArea, stageToLinear} from "../utils";
import {ParentDepartment} from "../models/ParentDepartment";


export const Record = types
    .model('Record', {
        id: types.identifier,
        name: types.optional(types.string, ""),
        layouts: types.optional(types.map(Layout), {
            "adjacency": {
                id: "adjacency",
                name: "adjacency",
                shape: {type: "circle"}
            },
            "plan": {
                id: "plan",
                name: "plan",
                shape: {type: "rect"}
            }
        }),
        targetGrossM: types.optional(types.number, 0),
        costPerSqM: types.optional(types.number, 2000),
        efficiencyFactor: types.optional(types.number, 80),
        targetNetM: types.optional(types.number, 0),
        department: types.optional(types.string, ""),
        parentDepartment: types.maybeNull(types.reference(ParentDepartment)),
        comment: types.optional(types.string, "")
    })
    .views(self => ({
        get rootStore() {
            return getRoot(self);
        },
        get isSelected() {
            let selection = getRoot(self).selection;
            if (!selection) return false;
            return selection.indexOf(self) > -1
        },
        get isDragging() {
            return self.isSelected && getRoot(self).stage.dragging;
        },
        get cost() {
            return self.targetGrossM * self.costPerSqM;
        },
        get area() {
            return self.shape.area;
        },
        get squareM() {
            return stageToArea(self.layouts.get('plan').shape.area);
        },
        get squareUnits() {
            return getRoot(self).unitsStore.toSqUnits(self.squareM);
        },
        get targetGrossUnits() {
            return getRoot(self).unitsStore.toSqUnits(self.targetGrossM);
        },
        get targetNetUnits() {
            return getRoot(self).unitsStore.toSqUnits(self.targetNetM);
        },
        get costPerSqUnit() {
            return getRoot(self).unitsStore.fromSqUnits(self.costPerSqM);//hack for inverse
        },
        get activeLayout() {
            return getRoot(self).stage.activeLayout;
        },
        get shape() {
            return self.layout.shape;
        },
        get x() {
            return self.layout.shape.position.x;
        },
        get y() {
            return self.layout.shape.position.y;
        },
        get height() {
            return self.layout.height;
        },
        get width() {
            return self.layout.width;
        },
        get planHeightM() {
            return (stageToLinear(self.layouts.get('plan').shape.height)).toFixed(2)
        },
        get planWidthM() {
            return (stageToLinear(self.layouts.get('plan').shape.width)).toFixed(2)
        },
        get planHeight() {
            return getRoot(self).unitsStore.toLinearUnits(self.planHeightM);
        },
        get planWidth() {
            return getRoot(self).unitsStore.toLinearUnits(self.planWidthM);
        },
        get perimeter() {
            return self.layout.perimeter;
        },
        get aspectRatio() {
            return self.layout.aspectRatio;
        },
        get layout() {
            return self.layouts.get(self.activeLayout);
        },
        get fill() {
            if (self.parentDepartment){
                return self.parentDepartment.color;
            }
            return "#aba4d1";
        },
        get isBelowTarget() {
            const tol =  getRoot(self).unitsStore.fromSqUnits(10);
            return self.squareM < (self.targetNetM - tol);
        },
        get isAboveTarget() {
            const tol =  getRoot(self).unitsStore.fromSqUnits(10);
            return self.squareM > (self.targetGrossM + tol)
        },
        get hasWarnings() {
            return self.isAboveTarget || self.isBelowTarget;
        },
        get matrix() {
            let matrix = compose(fromDefinition([
                {type: 'translate', tx: self.x, ty: self.y},
                {type: 'rotate', angle: self.shape.rotation}
            ]));
            return toString(matrix)
        },
        get globalVertices() {
            const svg = document.querySelector('svg');
            const shape = self.layouts.get('plan').shape;
            if (svg) {
                const vtx = svg.createSVGPoint();
                const ctm = self.SVGElement.getScreenCTM();
                const mtx = self.SVGElement.getScreenCTM();
                const matrixValue = self.rootStore.stage.inverseMatrixValue;

                mtx.a = matrixValue.a;
                mtx.b = matrixValue.b;
                mtx.c = matrixValue.c;
                mtx.d = matrixValue.d;
                mtx.e = matrixValue.e;
                mtx.f = matrixValue.f;

                return shape.vertexArray.map(([x, y]) => {
                    vtx.x = x;
                    vtx.y = y;
                    return vtx.matrixTransform(ctm).matrixTransform(mtx);
                }).map(({x, y}) => ({x, y}));
            }
        },
    }))
    .volatile(self => ({
        SVGElement: null,
        editingText: false,
        editingName: true,
        isActive: false,
        rotating: false,
        resizing: null
    }))
    .actions(self => ({
        beforeDestroy(){
            if (getRoot(self).selection.indexOf(self) > -1){
                getRoot(self).removeSelected(self);
            }
            if (getRoot(self).records.filter(record => record.parentDepartment === self.parentDepartment && record.id !== self.id).length === 0 && self.parentDepartment){
                destroy(self.parentDepartment);
            }
        },
        setResizing(dir) {
            self.resizing = dir;
        },
        setRotating(bool) {
            self.rotating = bool;
        },
        setPlanHeight(height) {
            const htM = getRoot(self).unitsStore.fromLinearUnits(height);
            self.layouts.get('plan').shape.setHeight(linearToStage(htM));
        },
        setPlanWidth(width) {
            const wdM = getRoot(self).unitsStore.fromLinearUnits(width);
            self.layouts.get('plan').shape.setWidth(linearToStage(wdM));
        },
        setEditingName(bool) {
            self.editingName = bool;
        },
        addLayout(layout) {
            self.layouts.put(layout);
        },
        setSVGElement(el) {
            // console.log('setSVGElement', el);
            self.SVGElement = el;
        },
        setShape(type) {
            let activeLayout = getRoot(self).stage.activeLayout;
            self.layouts.get(activeLayout).setShape(type);
        },
        setName(newName) {
            self.name = newName
        },
        updateMetadata(id, v) {
            if (id === null) return;

            if (id === 'targetGrossUnits') {//gross
                const valM = getRoot(self).unitsStore.fromSqUnits(v);
                self.setTargetGross(valM);
                self.efficiencyFactor = Math.round(100 * (self.targetNetM / self.targetGrossM));
                // console.log('Gross set: efficiencyFactor now '+self.efficiencyFactor);
                return;
            }
            if (id === 'efficiencyFactor') {
                self.efficiencyFactor = v;
                self.setTargetGross(Math.round(self.targetNetM / (self.efficiencyFactor / 100)));
                // console.log('EfficiencyFactor set: gross now - '+self.efficiencyFactor);

                return;
            }
            if (id === 'targetNetUnits') {
                const valM = getRoot(self).unitsStore.fromSqUnits(v);
                self.setTargetNet(valM);
                return;
            }
            if (id === 'costPerSqUnit') {
                self.costPerSqM = getRoot(self).unitsStore.toSqUnits(v);//hack for inverse
                return;
            }
            if (id === 'squareUnits') {
                const valM = getRoot(self).unitsStore.fromSqUnits(v);
                values(self.layouts).map(layout => {
                    if (!layout.shape.setRadius) {
                        const graphicArea = valM * PLAN_SQ_FT_RATIO;
                        const currentArea = layout.shape.area;
                        if (currentArea === 0) {
                            const side = Math.sqrt(graphicArea);
                            layout.shape.setWidth(side);
                            layout.shape.setHeight(side);
                        } else {
                            const ratio = Math.sqrt(graphicArea / currentArea);
                            layout.shape.setWidth(ratio * layout.shape.width);
                            layout.shape.setHeight(ratio * layout.shape.height);
                        }

                    }
                });
                return;
            }

            if (id === 'parentDepartment'){

                const dept = getRoot(self).departments.filter(dept => dept.name === v);

                if (dept.length > 0){
                    self[id] = dept[0];
                } else {
                    let newDept = getRoot(self).persistStore.addParentDepartment(v);
                    self[id] = newDept;
                }
            } else {
                if (self[id] !== undefined) {
                    self[id] = v;
                }
            }
        },
        setEditingText(bool) {
            self.editingText = bool;
        },
        initTargets(grossM) {
            grossM = getRoot(self).unitsStore.guessAtRounding(grossM);
            self.targetGrossM = grossM;
            self.setTargetNet(grossM * (self.efficiencyFactor / 100), false);
        },
        setCostPerSqUnit(value) {
            self.costPerSqM = getRoot(self).unitsStore.fromSqUnits(value);
        },
        setTargetNet(valM, match) {
            self.targetNetM = valM;
            self.setTargetGross(Math.round(self.targetNetM / (self.efficiencyFactor / 100)));
            // console.log('Net set: gross now - '+self.targetGrossM);

            const radiusM = Math.sqrt(valM / Math.PI);
            values(self.layouts).map(layout => {
                if (layout.shape.setRadius) {
                    const r = linearToStage(radiusM);
                    layout.shape.setRadius(r);
                } else {
                    if (match) {
                        let edge = Math.sqrt(valM * PLAN_SQ_FT_RATIO);
                        layout.shape.setWidth(edge);
                        layout.shape.setHeight(edge);
                    }
                }
            });
        },
        setTargetGross(val) {
            self.targetGrossM = val;
        },
        setActive(bool) {
            self.isActive = bool;
        },
        setComment(s) {
            self.comment = s;
        },
        remove() {
            getRoot(self).removeRecord(self);
        },
        resetPositions() {
            values(self.layouts).map(layout => {
                layout.shape.position.resetPosition();
            });
        }
    }));
