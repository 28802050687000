import React, {Component} from 'react';
import {observer} from "mobx-react";

@observer
class Handle extends Component {
    render() {
        const {mouseDown, mouseUp, x, y, size, shape, className, opacity} = this.props;

        if (shape === 'circle') {
            return (
                <circle
                    className={`Handle ${className}`}
                    opacity={opacity}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    cx={x}
                    cy={y}
                    r={size / 2}
                    stroke={"blue"}
                    fill={"white"}
                />
            );
        }
        return (
            <rect
                className={`Handle ${className}`}
                opacity={opacity}
                onMouseDown={mouseDown}
                onMouseUp={mouseUp}
                x={x}
                y={y}
                width={size}
                height={size}
                stroke={"blue"}
                fill={"white"}
            />
        );

    }
}

Handle.defaultProps = {
    shape: 'square',
    opacity: 1,
};

export default Handle;
