import React, {Component, useState} from 'react';
import {observer} from "mobx-react";

import ClampedBar from './charting/ClampedBar';
import FillBar from './charting/FillBar';
import StackedBar from './charting/StackedBar';

const _$ = x => `$${Math.round(x).toLocaleString()}`;

const SelectionView = observer(({store, selection}) => {
    const _SF = store.unitsStore.formatAreaLabel;

    function selectGroup(group) {
        store.clearSelected();
        group.forEach(element => store.setSelected(element));
    }

    if (selection.length > 5) {
        const targetGroups = selection.reduce((groups, element) => {
            if (element.isAboveTarget) {
                groups.over.push(element);
            }
            else if (element.isBelowTarget) {
                groups.under.push(element);
            }
            else {
                groups.at.push(element);
            }

            return groups;
        }, { at: [], over: [], under: [] });

        return (
            <div className="SelectionView">
                <p className="primary">{selection.length} Elements Selected</p>

                {Object.keys(targetGroups).map(group => {
                    const targets = targetGroups[group];

                    if (targets.length) {
                        return (
                            <div className="target-group">
                                <h5>{targets.length} Element{targets.length > 1 ? 's':''} {group} target</h5>
                                <button onClick={() => selectGroup(targets)}>Make Selection</button>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
    else {
        return selection.map(record => {
            const {name, squareUnits, targetNetUnits, targetGrossUnits, fill} = record;
            const inRange = !record.hasWarnings;

            return (
                <div key={record.id} className={`record ${!inRange ? 'out-of-range' : null} ${store.app.presentationMode ? 'presentationMode' : null}`}>
                    <h5>{name || 'Unnamed'}</h5>

                    <ClampedBar
                        label={"Drawn"}
                        clamp={{min: targetNetUnits, max: targetGrossUnits}}
                        values={squareUnits}
                        ramp={[fill]}
                        format={_SF}
                        presentationMode={store.app.presentationMode}
                        onMatchTarget={mid => record.updateMetadata('squareUnits', mid)}
                    />

                    <div className="record__info">
                        <b className="square-gross">{Math.round(100 * squareUnits / targetGrossUnits)}%</b>
                        <span>Net <b>{_SF(targetNetUnits)} / {Math.round(100 * targetNetUnits / targetGrossUnits)}%</b></span>
                        <span>Gross <b>{_SF(targetGrossUnits)}</b></span>
                    </div>
                </div>
            );
        });
    }
});

const SummaryView = observer(({store}) => {
    const {departments, project} = store;
    const [programExpand, setProgramExpand] = useState(true);
    const [costExpand, setCostExpand] = useState(true);
    const _SF = store.unitsStore.formatAreaLabel;

    return (
        <div className="SummaryView">
            <div className="summary-group">
                <div className="header">
                    <h5>Program Summary</h5>
                    <button onClick={() => setProgramExpand(!programExpand)}>
                        {(programExpand ? '-' : '+')}
                    </button>
                </div>
                <div className="BarChart">
                    <label>Eff %</label>
                    <div
                        className="BarChart__graphic">{Math.round(100 * store.totalNetSquareUnits / store.totalTargetSquareUnits) || 0}%
                    </div>
                </div>

                <ClampedBar
                    label={"Drawn"}
                    values={store.totalActualSquareUnits}
                    clamp={{min: store.totalNetSquareUnits, max: store.totalTargetSquareUnits}}
                    ramp={['#696969']}
                    format={_SF}
                    presentationMode={store.app.presentationMode}
                />

                <StackedBar
                    label={"Net"}
                    values={store.totalNetSquareUnits}
                    total={store.totalTargetSquareUnits}
                    ramp={['#D9D9D9']}
                    format={_SF}
                    presentationMode={store.app.presentationMode}
                />

                <StackedBar
                    label={"Gross"}
                    values={store.totalTargetSquareUnits}
                    total={store.totalTargetSquareUnits}
                    ramp={['#AAAAAA']}
                    format={_SF}
                    presentationMode={store.app.presentationMode}
                />


                {programExpand &&
                <div className="DepartmentView">
                    <FillBar
                        label={`Total Gross Size All Departments`}
                        value={store.totalTargetSquareUnits}
                        total={store.totalTargetSquareUnits}
                        ramp={['#696969']}
                        format={_SF}
                    />

                    {store.persistStore.parentDepartments.map(d => (
                        <FillBar
                            key={d.id}
                            label={d.name}
                            value={d.gross}
                            total={store.totalTargetSquareUnits}
                            ramp={[d.color]}
                            format={_SF}
                        />
                    ))}
                </div>}
            </div>

            <div className="summary-group">
                <div className="header">
                    <h5>Cost Summary</h5>
                    <button onClick={() => setCostExpand(!costExpand)}>
                        {(costExpand ? '-' : '+')}
                    </button>
                </div>
                <div className="BarChart">
                    <label>$/{store.unitsStore.squareUnitsDisplay}</label>
                    <div className="BarChart__graphic">
                        ${(Math.round(store.totalCost/store.totalTargetSquareUnits) || 0).toLocaleString()}
                    </div>
                </div>

                <ClampedBar
                    label={"Total"}
                    values={store.totalCost}
                    clamp={{min: 0, max: project.programBudget}}
                    ramp={['#696969']}
                    format={_$}
                    presentationMode={store.app.presentationMode}
                />

                <StackedBar
                    label={"Target"}
                    values={Math.round(project.programBudget)}
                    total={Math.round(project.programBudget)}
                    ramp={['#D9D9D9']}
                    format={_$}
                    presentationMode={store.app.presentationMode}
                />

                {costExpand &&
                <div className="DepartmentView">
                    <FillBar
                        label={`Total Cost All Departments`}
                        value={store.totalCost}
                        total={store.totalCost}
                        ramp={['#696969']}
                        format={_$}
                    />

                    {store.persistStore.parentDepartments.map(d => (
                        <FillBar
                            key={d.id}
                            label={d.name}
                            value={d.cost}
                            total={store.totalCost}
                            ramp={[d.color]}
                            format={_$}
                        />
                    ))}
                </div>}
            </div>
        </div>
    );
});


@observer
class DashboardView extends Component {

    render() {
        const {store, toggleExpand, expanded} = this.props;
        const {selection, records} = store;

        return (
            <div className={"DashboardView " + (expanded ? "opened" : "closed")}>
                <div className="header">
                    <button onClick={toggleExpand}>
                        <i className="retina-filetypecontent-1083"/>
                    </button>

                    <h5>DASHBOARD</h5>
                </div>

                <div className="scrollbox">
                    <SelectionView store={store} selection={selection}/>
                    <SummaryView store={store}/>
                    <label><input type="checkbox" value={store.app.presentationMode} onClick={() => store.app.setPresentationMode(!store.app.presentationMode)} />Presentation Mode (hides overages)</label>
                </div>
            </div>
        );
    }

}

export default DashboardView;
