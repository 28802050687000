import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import { getSnapshot } from 'mobx-state-tree';
import {observer} from "mobx-react";
import {saveAs} from 'file-saver';

import App from "../App";
import store from "../stores/RootStore";
import Stage from "./Stage/Stage";

const PRGM = 'PRGM';
const CSV = 'CSV';
const SVG = 'SVG';


@observer
class ExportModal extends Component {

    captureFormatChange = (val) => {
        const {store, closeModal, appStore} = this.props;
        appStore.setExportModalState(val);
    };

    getExportOptions = () => {
        const {store, closeModal, appStore} = this.props;
        const exportOptions = [
            {name: PRGM, value: PRGM, subtext: 'Programming Tool project file'},
            {name: CSV, value: CSV, subtext: "Exports the 'Tabular' data."},
            {
                name: SVG,
                value: SVG,
                subtext: "Vector file for illustrator of all elements that have been drawn on the canvas."
            },
        ];
        let displayData = [];
        let len = exportOptions.length;
        let count = 0;
        let isChecked = (value) => {
            return appStore.exportModalState === value;
        };
        for (let exportOption of exportOptions) {
            displayData.push(
                <label>
                    <input type="radio"
                           value={exportOption.value}
                           onChange={() => this.captureFormatChange(exportOption.value)}
                           checked={isChecked(exportOption.value)}/>
                    <span className="maintext">{exportOption.name}</span>
                    <span className="subtext">{exportOption.subtext}</span>
                </label>);
            count++;
            if (len === count) {
                return displayData;
            }
        }
    };

    getFormatTitle = () => {
        return (<div>
            <div className="title-text">
                CHOOSE YOUR DOWNLOAD FILE FORMAT
            </div>
        </div>);
    };

    downloadPrgm() {
        const { store } = this.props;

        const appState = JSON.stringify(getSnapshot(store))
        const blob = new Blob([appState], {type: 'application/x-prgm;charset=utf-8'});

        saveAs(blob, `${store.persistStore.fileName}.prgm`);
    }

    downloadSvg() {
        // const data = new XMLSerializer().serializeToString(document.querySelector('#stage svg'));
        const data = ReactDOMServer.renderToString(<Stage exportMode={true} layout={store.stage.activeLayout} store={store}/>);
        const blob = new Blob([data], {type: "image/svg+xml;charset=utf-8"});
        saveAs(blob, `${store.stage.activeLayout}.svg`);
    }

    downloadCSV(data) {
        const blob = new Blob([data], {type: "text/csv;charset=utf-8"});
        saveAs(blob, "export.csv");
    }

    download() {
        const {appStore, closeModal} = this.props;

        if (appStore.exportModalState === SVG) {
            this.downloadSvg();
        } else if (appStore.exportModalState === PRGM) {
            this.downloadPrgm();
        }else if (appStore.exportModalState === CSV) {
            const csvdata = store.gridApi.getDataAsCsv();
            this.cleanAndDownloadCSV(csvdata, closeModal);
        }
        closeModal();
    }


    cleanAndDownloadCSV(csvdata, closeModal) {
        let formattedData = [];
        if (csvdata) {
            let headerData = csvdata.split('\n')[0];
            let data = csvdata.split('\n');
            if (data && data.length > 1) {
                let actualData = [];
                data.forEach((val, i, arr) => {
                    if (i === 0) {
                        return;
                    }
                    let newRowItems = [];
                    let rowItems = val.split(',');
                    rowItems.forEach((itemVal, j) => {
                        const checkVal = itemVal.replace(/"/g, "");
                        if (!isNaN(parseFloat(checkVal))) {
                            newRowItems.push(Math.round(parseFloat(checkVal)));
                        } else {
                            newRowItems.push(checkVal);
                        }
                    });
                    actualData.push(newRowItems.join(','));
                })
                formattedData.push(headerData);
                actualData.forEach((data, k) => {
                    formattedData.push(data);
                })
                this.downloadCSV(formattedData.join('\n'));
            } else {
                this.downloadCSV(csvdata);

            }

        }
    }


    render() {
        const {store, fbModel, closeModal} = this.props;

        return (
            <div className="ExportModal">
                <div className="header">
                    <i className="retina-theessentials-2538" /> Export
                </div>
                <div className="content">
                    <p>
                        <i>
                            This product currently supports the following export options.
                            We are working to release new export features to include additional file types.
                        </i>
                    </p>

                    <div className="format">
                        {this.getFormatTitle()}
                        <div className={"export-options"}>
                            {this.getExportOptions()}
                        </div>


                    </div>

                    <div className={"exportButtons"}>
                        <button className="cancelButton block ss-button lt-grey" onClick={closeModal}>
                            Cancel
                        </button>

                        <button className="exportButton block ss-button primary" onClick={() => {
                            this.download();
                        }}>
                            Export & Download
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExportModal;
