import {getRoot, types} from "mobx-state-tree";
import {boundingBox} from "../utils";
import {v4} from "node-uuid";
import {UNITS} from "./ProjectStore";

const Position = types
    .model("Position", {
        x: types.optional(types.number, 0),
        y: types.optional(types.number, 0),
    })
    .views(self => ({
        get position() {
            return {x: self.x, y: self.y}
        }
    }))
    .actions(self => ({
        incrementPosition(x, y) {
            self.x += x;
            self.y += y;
        },
        setPosition(x, y) {
            self.x = x;
            self.y = y;
        },
        copy(position) {
            self.x = position.x;
            self.y = position.y;
        },
        resetPosition() {
            self.x = 0;
            self.y = 0;
        }
    }));

const LinkedPosition = Position
    .named('LinkedPosition')
    .props({
        linkId: types.optional(types.string,'')
    })
    .actions(self => ({
        setLinkId(id) {
            self.linkId = id;
        }
    }))
    .views(self => ({}));

const ControlPoint = Position
    .named('ControlPoint')
    .props({
        type: types.enumeration("type", ["ortho", "vertex"]),
        id: types.identifier,
        index: types.number,
    })
    .actions(self => ({
        setType(type) {
            self.type = type;
        },
        setIndex(index) {
            self.index = index;
        }
    }))
    .views(self => ({}));

const Shape = types
    .model("Shape", {
        type: types.literal('shape'),
        rotation: types.optional(types.number, 0),
        position: types.optional(Position, () => Position.create({x: 0, y: 0}))
    })
    .views(self => ({}))
    .actions(self => ({
        incrementPosition(x, y) {
            self.position.incrementPosition(x, y);
        },
        resetPosition() {
            self.position.resetPosition();
        },
        setRotation(deg) {
            self.rotation += deg;
        }
    }));

const Rectangle = Shape
    .named('Rectangle')
    .props({
        type: types.literal('rect'),
        height: types.optional(types.number, 50),
        width: types.optional(types.number, 50)
    })
    .actions(self => ({
        setHeight(height) {
            self.height = height;
        },
        setWidth(width) {
            self.width = width;
        },
        incrementSize(w, h) {
            if (self.width += w > 10) {
                self.width += w;
            }
            if (self.height += w > 10) {
                self.height += h;
            }
        },
        setBounds(bounds) {
            self.position.setPosition(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
            const cBounds = self.boundingBox;
            const dimAdjust = Math.sqrt((bounds.width * bounds.height) / (cBounds.width * cBounds.height));
            self.width = self.width * dimAdjust;
            self.height = self.height * dimAdjust;
        }
    }))
    .views(self => ({
        get name() {
            return 'rect';
        },
        get perimeter() {
            return self.height + self.width;//NOTE - not a real perimeter, but this is just used for sorting
        },
        get area() {
            return self.height * self.width;
        },
        get boundingBox() {
            const {x, y, width, height} = self.box;
            return boundingBox(self.rotation, x, y, width, height);
        },
        get box() {
            return {
                x: self.position.x - (self.width / 2),
                y: self.position.y - (self.height / 2),
                width: self.width,
                height: self.height
            }
        },
        get vertexArray() {
            const x = 0; //self.position.x;
            const y = 0; //self.position.y;
            const width = self.width;
            const height = self.height;
            return [
                [x, y],
                [x + width, y],
                [x + width, y + height],
                [x, y + height],
            ];
        }
    }));


const orderedKeys = ['ne', 'se', 'sw', 'nw'];
const orderedCorners = [{x: 1, y: 0}, {x: 1, y: 1}, {x: 0, y: 1}, {x: 0, y: 0}];
const Polygon = Rectangle
    .named('Polygon')
    .props({
        type: types.literal('polygon'),
        controlPointType: types.optional(types.enumeration('type', ['ortho', 'vertex']), 'ortho'),
        vertices: types.array(LinkedPosition),
        controlPoints: types.map(ControlPoint)
    })
    .actions(self => ({
        setVertex(idx, x, y) {
            if (idx >= self.vertices.length) return;
            self.vertices[idx].setPosition(x, y);
        },
        clearControlPoint(id) {
            if (self.controlPoints.has(id)) {
                self.controlPoints.delete(id)
            }
            self.updateVertices();
        },
        convertToVertexControlPoints() {
            if (self.controlPointType === 'vertex') return;
            self.controlPointType = 'vertex';
            self.controlPoints.clear()
            self.vertices.forEach((v, i) => {
                if (!v.linkId) {
                    v.setLinkId(v4());
                }
                let controlPoint = ControlPoint.create({type: 'vertex', id: v.linkId, index:i});
                self.controlPoints.put(controlPoint);
                controlPoint.setPosition(v.x, v.y);
            });
        },
        insertControlPoint(idx, x, y) {
            self.controlPoints.forEach((controlPoint, key, map) => {
                if (controlPoint.index >= idx) {//create a gap at idx
                    controlPoint.setIndex(controlPoint.index + 1);
                }
            });
            let controlPoint = ControlPoint.create({type: 'vertex', id: v4(), index:idx});
            self.controlPoints.put(controlPoint);
            controlPoint.setPosition(x, y);
            self.updateVertices();
            return controlPoint;
        },
        updateVertices() {
            if (self.controlPointType === 'vertex') {
                self.controlPoints.forEach((cp, key, map) => {
                    console.log('updateVertex', cp.id, cp.index);
                    self.vertices[cp.index] = LinkedPosition.create({x: cp.x, y: cp.y, linkId:cp.id})
                });
            } else {
                self.vertices.clear();
                orderedKeys.forEach((dir, i) => {
                    let corner = orderedCorners[i];
                    if (!self.controlPoints.has(dir)) {
                        self.vertices.push(LinkedPosition.create(corner));
                    } else {
                        const cp = self.controlPoints.get(dir);
                        const trio = [
                            LinkedPosition.create({x: corner.x, y: cp.y}),
                            LinkedPosition.create({x: cp.x, y: cp.y}),
                            LinkedPosition.create({x: cp.x, y: corner.y}),
                        ];
                        if (i % 2 === 0) {
                            trio.reverse();
                        }
                        self.vertices.push(...trio);
                    }
                });
            }
        },
        setControlPoint(type, id, x, y) {
            if (!id) return;
            console.log('setControlPoint', type, id, self.controlPoints.has(id), x, y);
            if (!self.controlPoints.has(id)) {
                console.log('setControlPoint', 'create new');
                let controlPoint = ControlPoint.create({type: type, id: id, index:0});
                self.controlPoints.put(controlPoint);
                controlPoint.setPosition(x, y);
                self.updateVertices();
                return;
            }
            self.controlPoints.get(id).setPosition(x, y);
            if (self.controlPointType === 'ortho') {
                let idx = 0;
                orderedKeys.forEach((dir, i) => {
                    let corner = orderedCorners[i];
                    if (!self.controlPoints.has(dir)) {
                        idx++;
                    } else {
                        const cp = self.controlPoints.get(dir);
                        const trio = [
                            {x: corner.x, y: cp.y},
                            {x: cp.x, y: cp.y},
                            {x: cp.x, y: corner.y},
                        ];
                        if (i % 2 === 0) {
                            trio.reverse();
                        }
                        trio.forEach((v, i) => {
                            self.setVertex(idx++, v.x, v.y);
                        });
                    }
                });
            } else {
                self.updateVertices();
            }
        },
        // setVertices(vertices) {
        //     self.vertices = vertices;
        // },
    }))
    .views(self => ({
        get name() {
            return 'polygon';
        },
        get sizedVertices() {
            // if (!self.vertices) return [];
            return self.vertices.map(v => {
                return {
                    linkId: v.linkId,
                    x: v.x * self.width,
                    y: v.y * self.height
                };
            });
        },
        get vertexArray() {
            const x = 0;//self.position.x;
            const y = 0;//self.position.y;
            return self.vertices.map(v => {
                return [
                    x + v.x * self.width,
                    y + v.y * self.height
                ];
            });
        },
        get area() {
            let total = 0;
            const {vertices} = self;
            for (let i = 0, len = vertices.length; i < len; i++) {
                const addX = vertices[i].x;
                const addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
                const subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
                const subY = vertices[i].y;

                total += (addX * addY * 0.5);
                total -= (subX * subY * 0.5);
            }

            return Math.abs(total) * self.height * self.width;
        },
    }));

const Circle = Shape
    .named('Circle')
    .props({
        type: types.literal('circle'),
        rx: types.optional(types.number, 50),
    })
    .actions(self => ({
        setRadius(radius) {
            self.rx = radius;
        },
        setBounds(bounds) {
            self.position.setPosition(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
            const cBounds = self.boundingBox;
            const dimAdjust = Math.sqrt((bounds.width * bounds.height) / (cBounds.width * cBounds.height));
            self.rx = self.rx * dimAdjust;
        },
        incrementSize(w, h) {
            self.rx += Math.min(w, h);
        },
    }))
    .views(self => ({
        get name() {
            return 'circle';
        },
        get height() {
            return self.rx * 2;
        },
        get width() {
            return self.rx * 2;
        },
        get perimeter() {
            return (2 * self.rx * Math.PI);
        },
        get area() {
            return (self.rx * self.rx * Math.PI);
        },
        get boundingBox() {
            return self.box;//no different for circles
        },
        get box() {
            return {x: self.position.x - self.rx, y: self.position.y - self.rx, width: self.rx * 2, height: self.rx * 2}
        }
    }));

export const Layout = types
    .model('Layout', {
        id: types.identifier,
        name: types.enumeration("name", ["plan", "adjacency"]),
        shape: types.maybeNull(types.union(Shape, Rectangle, Polygon, Circle)),
        isPlaced: false
    })
    .views(self => ({
        get height() {
            return self.shape.height
        },
        get width() {
            return self.shape.width
        },
        get perimeter() {
            return self.shape.perimeter
        },
        get rotation() {
            return self.shape.rotation
        }
    }))
    .actions(self => ({
        incrementPosition(x, y) {
            self.shape.incrementPosition(x, y);
        },
        incrementSize(w, h) {
            self.shape.incrementSize(w, h);
        },
        setRotation(deg) {
            self.shape.setRotation(deg);
        },
        setShape(type) {
            const oldShape = self.shape;
            if (oldShape && oldShape.type === type) return;
            switch (type) {
                case 'circle':
                    self.shape = Circle.create({type: 'circle'});
                    break;
                case 'polygon':
                    self.shape = Polygon.create({type: 'polygon'});
                    self.shape.updateVertices();//not sure how to run a 'constructor' in mst...
                    break;
                default:
                    self.shape = Rectangle.create({type: 'rect'});
            }
            if (oldShape) {
                self.shape.position.copy(oldShape.position);
                self.shape.width = oldShape.width;
                self.shape.height = oldShape.height;
                self.shape.rotation = oldShape.rotation;
            }
        },
        setName(newName) {
            self.name = newName;
        },
        markPlaced() {
            self.isPlaced = true;
        }
    }));
