import {getRoot, types} from "mobx-state-tree";
import {setUndoManager} from "../stores/RootStore";

const colors = ["#b6d187", "#fbb684", "#e7c57c", "#f69785", "#c6a4cd", "#aabbe1", "#9eaf9b", "#c9ad8f", "#ca9d99", "#b0a88c", "#ababab", "#c0bbdd", "#cddeab", "#fdcba5", "#eed5a3", "#f9b4a4", "#d4bddc", "#c1cde9", "#b6c2b2", "#dac7b2", "#d6b5b1", "#c9c3b0", "#c7c8ca", "#d6d3ea", "#dee9c9", "#fee0c7", "#f3e3c2", "#fcd3c6", "#e4d7ea", "#d9e0f2", "#cfd6cc", "#e6dacc", "#e3cecb", "#dbd7ca", "#dcddde"];

export const ParentDepartment = types
    .model("ParentDepartment", {
        id: types.identifier,
        name: types.string,
        color: types.optional(types.string, '#ccc')
    })
    .views(self => ({
        get cost() {
            let cost = 0;
            getRoot(self).persistStore.records.forEach(record => {
                if (record.parentDepartment === self){
                    cost += record.cost;
                }
            });
            return cost;
        },
        get gross() {
            let gross = 0;
            getRoot(self).persistStore.records.forEach(record => {
               if (record.parentDepartment === self){
                    gross += record.targetGrossUnits;
               }
            });
            return gross;
        }
    }))
    .actions(self => ({
        afterCreate() {
            self.color = colors.shift();
        },
        beforeDestroy(){
            colors.push(self.color);
        },
        setName(name){
            self.name = name;
        }
    }));