import React from 'react';
import { observer } from 'mobx-react';

import Bar from './Bar';


function FillBar({ label, value, total, format, ramp }) {
    if (typeof format !== 'function') {
        format = x => x;
    }

    return (
        <div className="BarChart FillBar">
            <label>{label}</label>

            <div className="BarChart__graphic">
                <Bar values={value} total={total} ramp={ramp} />

                <div className="FillBar__value">
                    {format(value)}
                </div>
            </div>
        </div>
    );
}


export default observer(FillBar);
