import React from 'react';
import ReactDOM from 'react-dom';

import ReactGA from 'react-ga';
if (window.location.host.split('.')[0] === 'staging') {
    ReactGA.initialize('UA-72138679-12');
} else {
    ReactGA.initialize('UA-72138679-13');
}

ReactGA.pageview(window.location.pathname + window.location.search);

import './index.css';
import App from './App';
import store from "./stores/RootStore";
import FirebaseModel from "./FirebaseModel";
import ImageHandler from "./ImageHandler";

const fbModel = new FirebaseModel(store.persistStore);
store.setFbModel(fbModel);
store.app.imageHandler = new ImageHandler(store.app);

window.store = store;

ReactDOM.render(<App store={store} fbModel={fbModel}/>, document.getElementById('app-root'));
