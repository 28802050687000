import {observable} from 'mobx';
import {getRoot, types} from "mobx-state-tree";
import {inverse} from 'transformation-matrix';
import Shape from "../components/Stage/Shape";
import React from "react";
import {inflateRect} from "../utils";

const MatrixValue = types
    .model("matrixValue", {
        x: types.optional(types.number, 0),
        y: types.optional(types.number, 0),
        a: types.optional(types.number, 1),
        b: types.optional(types.number, 0),
        c: types.optional(types.number, 0),
        d: types.optional(types.number, 1),
        e: types.optional(types.number, -5000),
        f: types.optional(types.number, -5000),
      })
      .actions(self => ({
          setValue(val) {
              self = Object.assign(self, val);
          }
      }));

export const StageStore = types
    .model("StageStore", {
        activeLayout: types.optional(types.string, 'adjacency'),
        showArrow: types.optional(types.boolean, true),
        matrixPos: types.optional(types.map(MatrixValue), {
            "adjacency": {
                a: 1,
                b: 0,
                c: 0,
                d: 1,
                e: -5000,
                f: -5000
            },
            "plan": {
                a: 1,
                b: 0,
                c: 0,
                d: 1,
                e: -5000,
                f: -5000
            }
        })
    })
    .volatile(self => ({
        clipboard: [],
        activeTool: "pan",
        drawing: false,
        dragging: false,
        resizing: false,
        marqueeSelect: false,
        clickPosition: {x: null, y: null},
        mouseClickPosition: {x: null, y: null},
        mousePosition: {x: 0, y: 0},
        viewer: null,
        toolData: observable([]),
    }))
    .views(self => ({
        get imageName(){
            return getRoot(self).persistStore.imageName;
        },
        get imageURL(){
            return getRoot(self).persistStore.imageURL;
        },
        get imageSize(){
            return getRoot(self).persistStore.imageSize;
        },
        get imageScale(){
            return getRoot(self).persistStore.imageScale;
        },
        get matrixValue(){
            return self.matrixPos.get(self.activeLayout);
        },
        get scale() {
            return Math.sqrt(Math.pow(self.matrixValue.a, 2) + Math.pow(self.matrixValue.b, 2));
        },
        get inverseMatrixValue() {
            return inverse(self.matrixValue);
        },
        get hasBackgroundImage() {
            return self.imageName != '' && self.imageURL != '';
        },
        get hasScaleCoords() {
            return self.activeTool === 'scale' && self.toolData.length >= 2;
        },
    }))
    .actions(self => ({
        setImage(name, base64String, widthHeightArray) {
            getRoot(self).persistStore.setImage(name, base64String, widthHeightArray);
        },
        setMarqueeSelect(bool) {
            self.marqueeSelect = bool;
        },
        clearToolData() {
            self.toolData = observable([]);
        },
        addToolData(data) {
            self.toolData.push(data);
        },
        setTool(toolName) {
            self.clearToolData();
            self.activeTool = toolName;
        },
        setMatrixPos(value) {
            self.matrixValue.setValue(value);
        },
        setClickPosition(x, y) {
            self.clickPosition = {x: x, y: y};
        },
        setMouseClickPosition(x, y) {
            self.mouseClickPosition = {x: x, y: y};
        },
        setActiveLayout(id) {
            self.activeLayout = id;
        },
        setToggleArrow(id) {
            self.showArrow = !self.showArrow;
        },
        setMousePosition(x, y) {
            self.mousePosition = {x: x, y: y};
        },
        setDragging(bool) {
            self.dragging = bool;
        },
        setDrawing(bool) {
            self.drawing = bool;
        },
        setImageScale(scale = 1) {
            getRoot(self).persistStore.setImageScale(scale);
        },
        setViewer(viewer) {
            self.viewer = viewer;
        },
        fitBounds(inflateBy = 1) {
            let root = getRoot(self);
            let activeBounds = root.activeBounds;//diagram: https://aggie.io/3s24ibz64c
            if (activeBounds.width === 0) return;
            const {viewableArea, clientWidth, clientHeight} = root.app;
            const adjusted = {
                x: activeBounds.x,
                y: activeBounds.y,
                width: activeBounds.width,
                height: activeBounds.height,
            };

            //first, match and center aspect
            if (activeBounds.width / activeBounds.height > viewableArea.width / viewableArea.height) {
                adjusted.height = activeBounds.width * viewableArea.height / viewableArea.width;
                adjusted.y = activeBounds.y - (adjusted.height - activeBounds.height) / 2;
            } else {
                adjusted.width = activeBounds.height * viewableArea.width / viewableArea.height;
                adjusted.x = activeBounds.x - (adjusted.width - activeBounds.width) / 2;
            }

            //second, add additional space to the bounding box to account for the tool panels
            adjusted.width = adjusted.width / (viewableArea.width / clientWidth);
            adjusted.height = adjusted.height / (viewableArea.height / clientHeight);

            adjusted.x -= (viewableArea.left / viewableArea.width) * adjusted.width / 2;
            adjusted.y -= (viewableArea.top / viewableArea.height) * adjusted.height / 2;

            const {x, y, width, height} = inflateRect(adjusted, inflateBy);
            self.viewer.fitSelection(x, y, width, height);
        }
    }));
