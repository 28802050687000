import React, {Component} from 'react';
import {observer} from "mobx-react";
import PlanShape from "./PlanShape";

@observer
class Circle extends Component {
    constructor(props) {
        super(props);
        this.objRef = React.createRef();
    }

    componentDidMount() {
        this.props.element.setSVGElement(this.objRef.current);
    }

    render() {
        let rotationString = `rotate(${this.props.element.shape.rotation}, ${this.props.element.width / 2}, ${this.props.element.height / 2})`;

        const {exportMode} = this.props;

        let textAreaStyle = {
            width: this.props.element.shape.width,
            height: this.props.element.shape.height
        };
        const {scale} = this.props.store.stage;

        const fontSize = (baseVal) => {
            const logScale = Math.pow(scale, 0.8);
            return (baseVal / logScale);
        };

        const textStyle = (baseVal, wrap) => {
            const style = {fontSize: fontSize(baseVal) + 'em'};
            if (!wrap) {
                style.whiteSpace = 'nowrap';
            }
            return style;
        };
        const scaledSize = {
            width: scale * this.props.element.shape.width,
            height: scale * this.props.element.shape.height,
        };
        // console.log({scale, width: scaledSize.width});

        const breakPoints = {//calibrated
            singleRowMax: {
                small: {scale: 0.75, height: 24},
                large: {scale: 5, height: 90},
            },
            doubleRowMax: {
                small: {scale: 0.75, height: 42},
                large: {scale: 5, height: 125},
            },
            tripleRowMax: {
                small: {scale: 0.75, height: 60},
                large: {scale: 5, height: 160},
            },
            widthMax: {
                small: {scale: 0.35, width: 40},
                large: {scale: 5, width: 150},
            }
        };

        const breakPoint = (key, prop) => {
            const bp = breakPoints[key];
            //use linear interpolation between small and large breakpoints to get breakpoint 'height' for current scale
            const norm = (scale - bp.small.scale) / (bp.large.scale - bp.small.scale);
            return bp.small[prop] + norm * (bp.large[prop] - bp.small[prop]);
        };

        const nameScale = 1.2;

        const nameWrap = () => {
            return scaledSize.height > breakPoint('tripleRowMax', 'height');
        };
        const canDisplayName = () => {
            return scaledSize.height > breakPoint('doubleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };
        const canDisplayArea = () => {
            return scaledSize.height > breakPoint('singleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        let labelSize = Math.sqrt(this.props.element.shape.rx * this.props.element.shape.rx * 2);

        if (exportMode) {
            const textCenter = {x:this.props.element.shape.rx, y: this.props.element.shape.rx}
            return (
                <g transform={rotationString}>
                    <circle
                        fill={this.props.element.fill}
                        r={this.props.element.shape.rx}/>

                    <g>
                        <text textAnchor="middle" fill={'#111111'} y={-10}>{this.props.element.name}</text>
                        <text textAnchor="middle" fill={'#222222'} y={10}>{store.unitsStore.displayArea(this.props.element.targetNetM)}</text>
                    </g>
                </g>
            );
        }

        return (
            <g className="Circle" transform={rotationString}>

                <circle
                    fill={this.props.element.fill}
                    r={this.props.element.shape.rx}/>

                {!this.props.editLabel &&
                <foreignObject
                    className="label"
                    x={-labelSize / 2}
                    y={-labelSize / 2}
                    width={labelSize}
                    height={labelSize}>
                    <div className="label-text">
                        <div className="label-text-inner">
                            {canDisplayName() && <div className="label-name"
                                                      style={textStyle(1.2, nameWrap())}>{this.props.element.name}</div>}
                            {canDisplayArea() && <div className="label-area"
                                                      style={textStyle(1.3, false)}>{store.unitsStore.displayArea(this.props.element.targetNetM)}</div>}
                        </div>
                    </div>
                </foreignObject>}

                <circle
                    ref={this.objRef}
                    className={'object ' + ((this.props.element.isSelected) ? 'selected ' : ' ') + ((this.props.element.isActive) ? 'active' : '')}
                    onMouseDown={this.props.handleMouseDown}
                    onDoubleClick={this.props.handleEditingName}
                    onClick={this.props.handleMouseClick}
                    onMouseOver={this.props.handleMouseOver}
                    onMouseOut={this.props.handleMouseOut}
                    fill={"transparent"}
                    r={this.props.element.shape.rx}/>
                }

                {this.props.editLabel &&
                <foreignObject
                    className="label"
                    x={-labelSize / 2}
                    y={-labelSize / 2}
                    width={labelSize}
                    height={labelSize}>
                    <div className="name-edit">
                            <textarea
                                autoFocus
                                style={textAreaStyle}
                                value={this.props.element.name}
                                onMouseDown={(e) => e.stopPropagation()}
                                onKeyPress={this.props.handleKeyPress}
                                onChange={(e) => this.props.editName(e.target.value)}
                            />
                    </div>
                </foreignObject>}
            </g>
        );
    }
}
Circle.defaultProps = {
    exportMode: false,
};
export default Circle;
