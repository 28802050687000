import {observable} from 'mobx';
import {types} from "mobx-state-tree";
import {inverse} from 'transformation-matrix';
import {guessAtRounding} from "../utils";
import React from "react";

//NOTE... squareFeet refactored to squareUnits
//underlying metrics are in meters (and stageToArea, stageToLinear) return meters
//targetGross etc. stored in square meters
//targetGrossUnits etc will get and set square meters using currently selected units
//for table etc where these values are edited, we will use e.g. targetGrossUnits
//utils class has formatting for linear and square units based on active setting

export const M_TO_FT = 3.28084;
export const SQM_TO_SQFT = M_TO_FT * M_TO_FT;

export const UnitsStore = types
    .model("UnitsStore", {
        activeUnits: types.optional(types.enumeration("units", ["meters", "feet"]), 'feet'),
    })
    .volatile(self => ({}))
    .views(self => ({
        get currencyUnitsDisplay() {
            return '$';
        },
        get squareUnitsDisplay() {
            if (self.activeUnits === 'meters') {
                return 'm²';//<span>m<sup>2</sup></span>
            }
            return 'ft²';//<span>ft<sup>2</sup></span>;
        },
        get linearUnitsDisplay() {
            if (self.activeUnits === 'meters') {
                return 'm';
            }
            return 'ft'
        },
        guessAtRounding(valM) {
            //return meters value but with 'rounding' for current units
            if (self.activeUnits === 'meters') {
                return guessAtRounding(valM);
            }
            return guessAtRounding(valM * SQM_TO_SQFT) / SQM_TO_SQFT;
        },

        toSqUnits(val) {
            if (self.activeUnits === 'meters') {
                return val;
            }
            return val * SQM_TO_SQFT;
        },
        fromSqUnits(val) {
            if (self.activeUnits === 'meters') {
                return val;
            }
            return val / SQM_TO_SQFT;
        },
        toLinearUnits(val) {
            if (self.activeUnits === 'meters') {
                return val;
            }
            return val * M_TO_FT;
        },
        fromLinearUnits(val) {
            if (self.activeUnits === 'meters') {
                return val;
            }
            return val / M_TO_FT;
        },
        getDisplayText(genericText) {
            if (genericText === '$/unit') {
                return self.currencyUnitsDisplay + '/' + self.squareUnitsDisplay;
            }
        },
        displayArea(valM) {
            return self.formatArea(self.toSqUnits(valM)) + ' ' + self.squareUnitsDisplay;
        },
        displayLinear(valM) {
            return self.formatLinear(self.toLinearUnits(valM)) + ' ' + self.linearUnitsDisplay;
        },
        //note for format val is already converted
        formatAreaLabel(val) {
            return self.formatArea(val) + ' ' + self.squareUnitsDisplay;
        },
        formatArea(val) {
            return Math.round(val).toLocaleString();
        },
        formatLinearLabel(val) {
            return self.formatLinear(val) + ' ' + self.squareUnitsDisplay;
        },
        formatLinear(val) {//note val already converted
            return Math.round(val).toLocaleString();
        }
    }))
    .actions(self => ({
        setUnitsMode(mode = 'feet') {
            self.activeUnits = mode === 'feet' ? 'feet' : 'meters';
        },

        toggleUnitsMode() {
            if (self.activeUnits === 'meters') {
                self.activeUnits = 'feet';
            } else  {
                self.activeUnits = 'meters';
            }
        }
    }));
