import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";

import Shape from "./Shape";
import DrawCircle from "./DrawCircle";
import SelectionBox from "./SelectionBox";
import MarqueeTool from "./MarqueeTool";
import Arrow from "./Arrow";

@observer
class AdjacencyView extends Component {
    constructor(props){
        super(props);
    }
    componentDidMount = () => {
        this.props.store.unplacedRecords.map((record, i) => {
            record.layout.shape.resetPosition();
            record.layout.incrementPosition(i * 100 + i * 10, 0)
        });
    };
    render() {
        const {store, exportMode} = this.props;
        const {stage} = this.props.store;

        return(
            <Fragment>
                {store.sortedRecords.map((el) =>
                    <Shape
                        store={store}
                        exportMode={exportMode}
                        key={el.id}
                        element={el}
                    />
                )}

                {store.arrows.map((el) =>
                    <Arrow
                        store={store}
                        exportMode={exportMode}
                        key={`${el.id}${String(stage.scale)}`}
                        element={el}
                    />
                )}

                {!exportMode && store.selection.length && !stage.dragging && !stage.resizing &&
                    <SelectionBox store={store} />
                }

                {!exportMode && stage.activeTool === 'shape' && stage.drawing &&
                    <DrawCircle store={store} />
                }

                {!exportMode && stage.marqueeSelect &&
                    <MarqueeTool store={store} />
                }
            </Fragment>
        )
    }
}
AdjacencyView.defaultProps = {
    exportMode: false,
};

export default AdjacencyView;
