import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";

@observer
class DrawArrow extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const [x1, y1, x2, y2] = [this.props.x1, this.props.y1, this.props.x2, this.props.y2];
        return (
            <g>
                <defs>
                    <marker id="arrowhead" markerWidth="10" markerHeight="7"
                            refX="10" refY="3.5" orient="auto">
                        <polygon points="0 0, 10 3.5, 0 7" />
                    </marker>
                </defs>
                <path className="DrawArrow"
                      markerEnd="url(#arrowhead)"
                      d={`M${x1} ${y1} L${x2} ${y2}`}
                />
                <circle
                    className={"DrawArrowOrigin"}
                    fill={"white"}
                    cx={x1}
                    cy={y1}
                    r={5}
                />
                <circle
                    className={"DrawArrowTarget"}
                    fill={"white"}
                    cx={x2}
                    cy={y2}
                    r={5}
                />
            </g>
        );
    }
}

export default DrawArrow;
