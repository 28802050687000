import React from 'react';
import { observer } from 'mobx-react';


function Bar({ values, total, ramp = [undefined] }) {
    if (!Array.isArray(values)) {
        values = [values];
    }

    const getStyle = (i, value)=> {
        const style = {
            width: `${(100 * value / total) || 0}%`,
        };
        if (ramp[i]) {
            style.background = ramp[i];
        }
        return style;
    }

    return (
        <div className="Bar">
            {values.map((value, i) => (
                <div
                    key={i}
                    className="Bar__graphic"
                    style={getStyle(i, value)}
                />
            ))}
        </div>
    );
}


export default observer(Bar);
