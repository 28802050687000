import React, {Component} from 'react';
import {observer} from "mobx-react";
import PlanView from "./PlanView";

@observer
class Arrow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.cancelEditMode);
    }

    handleClick = (e) => {
        e.stopPropagation();
        this.props.store.toggleArrowSelected(this.props.element);
        document.addEventListener('click', this.cancelEditMode);
    };

    cancelEditMode = () => {
        this.props.store.toggleArrowSelected(this.props.element);
        document.removeEventListener('click', this.cancelEditMode);
    };

    handleKeyDown = (e) => {
        let key = e.key;
        if (!this.props.element.isSelected) return;
        if (key === "Delete" || key === "Backspace") {
            this.props.store.removeArrow(this.props.element);
        }
    };

    dragConnection = (e, point) => {
        e.stopPropagation();

        let {element} = this.props;
        let previousPoint;

        this.setState({
            dragging: true
        });

        if (point === "origin") {
            previousPoint = element.origin;
            element.setOrigin(null);
        } else if (point === "target") {
            previousPoint = element.target;
            element.setTarget(null);
        }

        const onUp = (e) => {
            document.removeEventListener('mouseup', onUp);
            this.setState({
                dragging: false
            });

            let activeRecord = this.props.store.activeRecords[0];
            if (activeRecord) {
                if (point === "origin" && activeRecord.id !== element.target.id) {
                    element.setOrigin(activeRecord);
                    return;
                } else if (point === "target" && activeRecord.id !== element.origin.id) {
                    element.setTarget(activeRecord);
                    return;
                }
            }

            if (point === "origin") {
                element.setOrigin(previousPoint);
            } else if (point === "target") {
                element.setTarget(previousPoint);
            }
        };

        document.addEventListener('mouseup', onUp);
    };

    nodeEdge(a, b, r) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        if (dist === 0) {
            return a;
        }
        let frac = r / dist;
        return {
            x: a.x - frac * dx,
            y: a.y - frac * dy
        }
    }

    rectEdge(x, y, width, height, isSource) {
        if (isSource) {
            return {x: x + width / 2, y: y};
        }
        return {x: x - width / 2, y: y};
    }

    render() {
        let x1, y1, x2, y2;
        let {element, exportMode} = this.props;
        let {store} = this.props;

        if (element.origin) {
            x1 = element.origin.x;
            y1 = element.origin.y;
        } else {
            x1 = store.stage.mousePosition.x;
            y1 = store.stage.mousePosition.y;
        }

        if (element.target) {
            x2 = element.target.x;
            y2 = element.target.y;
        } else {
            x2 = store.stage.mousePosition.x;
            y2 = store.stage.mousePosition.y;
        }


        const a = {x: x1, y: y1};
        const b = {x: x2, y: y2};
        let arrowStartP = {x: x1, y: y1}, arrowEndP = {x: x2, y: y2};

        const getRectDist = (record) => {
            return Math.min(record.width, record.height) * 0.4;
        };

        if (element.origin) {
            if (element.origin.shape.type === 'rect' || element.origin.shape.type === 'polygon') {
                arrowStartP = this.nodeEdge(a, b, getRectDist(element.origin));
                console.log('arrowStartP', arrowStartP);
            } else {
                arrowStartP = this.nodeEdge(a, b, element.origin.shape.rx);
            }

        }
        if (element.target) {
            if (element.target.shape.type === 'rect' || element.target.shape.type === 'polygon') {
                arrowEndP = this.nodeEdge(b, a, getRectDist(element.target));
                console.log('arrowEndP', arrowEndP);
            } else {
                arrowEndP = this.nodeEdge(b, a, element.target.shape.rx);
            }
        }

        const isSelected = this.props.element.isSelected;

        if (exportMode) {
            return <path
                markerEnd={(isSelected ? `url(#arrowhead-selected)` : `url('#arrowhead-unselected')`)}
                stroke={'black'}
                d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
            />
        }


        return (
            <g className={"Arrow " + (this.state.dragging ? 'dragging' : '')}>
                <g>
                    <path
                        className={"arrow " + (isSelected ? 'editMode' : '') + ((!store.stage.showArrow && store.stage.activeLayout === 'plan') ? 'hide' : '')}
                        markerEnd={(isSelected ? `url(#arrowhead-selected)` : `url('#arrowhead-unselected')`)}
                        d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
                    />
                    <path
                        className={"clickArea"}
                        onClick={this.handleClick}
                        d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
                    />
                </g>

                {isSelected &&
                <g>
                    <circle
                        className={"arrowOrigin"}
                        fill={"white"}
                        cx={arrowStartP.x}
                        cy={arrowStartP.y}
                        r={5}
                        onMouseDown={(e) => this.dragConnection(e, "origin")}
                    />
                    <circle
                        className={"arrowTarget"}
                        fill={"white"}
                        cx={arrowEndP.x}
                        cy={arrowEndP.y}
                        r={5}
                        onMouseDown={(e) => this.dragConnection(e, "target")}
                    />
                </g>}
            </g>
        );
    }
}

Arrow.defaultProps = {
    exportMode: false,
};

export default Arrow;
