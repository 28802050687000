import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";

import {undoManager} from "../../stores/RootStore";
import {stageToArea, stageToLinear} from "../../utils";

@observer
class DrawRect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            originalPosition: {x: 0, y: 0}
        };
    }

    componentDidMount = () => {
        const pos = this.props.store.stage.clickPosition;
        this.setState({
            originalPosition: {x: pos.x, y: pos.y}
        });
    };
    componentWillUnmount = () => {
        const mousePosition = this.props.store.stage.mousePosition;
        let x, y;
        x = Math.min(this.state.originalPosition.x, mousePosition.x);
        y = Math.min(this.state.originalPosition.y, mousePosition.y);

        if (mousePosition.x - this.state.originalPosition.x < 0) {
            x = mousePosition.x;
        }

        if (mousePosition.y - this.state.originalPosition.y < 0) {
            y = mousePosition.y;
        }

        let width = Math.abs(mousePosition.x - this.state.originalPosition.x);
        let height = Math.abs(mousePosition.y - this.state.originalPosition.y);

        if (width > 10 && height > 10) {
            undoManager.startGroup(() => {
                this.props.store.createRecord();
                let record = this.props.store.records[this.props.store.records.length - 1];
                record.shape.incrementPosition(x + width / 2, y + height / 2);
                record.shape.setHeight(height);
                record.shape.setWidth(width);
                const w = stageToLinear(width);
                const h = stageToLinear(height);
                record.initTargets(w * h);
                record.layout.markPlaced();
                this.props.store.clearSelected();
                this.props.store.setSelected(record);
            });
            undoManager.stopGroup();
        }
    };

    render() {
        const {store} = this.props;
        const mousePosition = store.stage.mousePosition;
        let x, y;
        x = Math.min(this.state.originalPosition.x, mousePosition.x);
        y = Math.min(this.state.originalPosition.y, mousePosition.y);

        if (mousePosition.x - this.state.originalPosition.x < 0) {
            x = mousePosition.x;
        }

        if (mousePosition.y - this.state.originalPosition.y < 0) {
            y = mousePosition.y;
        }

        let width = Math.abs(mousePosition.x - this.state.originalPosition.x);
        let height = Math.abs(mousePosition.y - this.state.originalPosition.y);
        const w = stageToLinear(width);
        const h = stageToLinear(height);
        const units = {
            w: store.unitsStore.toLinearUnits(w),
            h: store.unitsStore.toLinearUnits(h),
        };
        return (
            <Fragment>
                <g className={"drawRect"}
                   transform={`translate(${x + width / 2}, ${y + height / 2})`}>
                    <rect
                        stroke={"black"}
                        strokeDasharray={"4"}
                        fill={"transparent"}
                        x={-width / 2}
                        y={-height / 2}
                        width={width}
                        height={height}
                    />
                    <text textAnchor="middle">{Math.round(units.w * units.h)}</text>
                    <text y={20} textAnchor="middle">{Math.round(units.w)} x {Math.round(units.h)}</text>
                </g>
            </Fragment>
        )
    }
}

export default DrawRect;
