import path from 'path';
import firebase from 'firebase';
import {reaction} from 'mobx';
import {applySnapshot, getRoot, onPatch, onSnapshot} from "mobx-state-tree";

import firebaseConfig from "./config";


export default class FirebaseModel {

    constructor(store) {
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        firebase.auth().onAuthStateChanged(user => {
            store.setUser(user);
            this.loadDoc(store, user);

        });

        this.dataToWrite = null;

        const patcherId = String(store.patcherId);

        // Read
        reaction(
            () => store.fileHash,
            () => {
                if (store.fileHash && store.dbFileRef) {
                    //TODO: disabling multiplayer (reading from other users) TEMP
                    /*store.dbFileRef.on('value', snapshot => {
                        if (snapshot && snapshot.val()) {
                            store.loadSnapshot(snapshot.val());
                        }
                    });*/
                }
            }
        );

        let activeFileNameFetch = false;

        onPatch(store, ({op, path, value}) => {
            if (!store.fileHash) return;
            // console.log(op, path, value, 'activeFileNameFetch:', activeFileNameFetch);
            if (op === 'replace' && path === '/fileName') {
                const updateFileName = () => {
                    activeFileNameFetch = true;
                    // console.log('POSTING: '+store.getFileName());
                    fetch(`${firebaseConfig.apiURL}/updateFile`, {
                        method: 'POST',
                        cors: 'no-cors',
                        body: JSON.stringify({
                            fileId: store.fileHash,
                            fileName: store.getFileName(),
                            userEmail: store.user.email
                        }),
                    })
                        .then(response => response.json())
                        .then((res) => {
                            activeFileNameFetch = false;
                            // console.log(JSON.stringify(res), res.name, store.getFileName());
                            if (res.name !== store.getFileNameWithExtension()) {
                                // console.log('Calling updateFileName due to mismatch');
                                //updateFileName(); TODO: check if this is needed?
                            }
                        })
                        .catch((err) => {
                            activeFileNameFetch = false;
                            console.log('Err when creating: ' + err);
                        });
                };
                if (!activeFileNameFetch) {
                    updateFileName();
                }
            }
        });

        // Write
        onSnapshot(store, patch => {
            if (!store.fileHash) return;
            // console.log('onSnapshot: WRITE DATA');
            this.dataToWrite = {store, patch};
            // this.writeData(store, null, patch);
        });

        //DEBOUNCE
        setInterval(() => {
            if (this.dataToWrite) {
                const {store, patch} = this.dataToWrite ;
                this.writeData(store, null, patch);
                this.dataToWrite = null;
            }
        }, 1000);

        /*
        onPatch(store, patch => {
            if (!window.location.search){
                if (store.patcherId !== patcherId) {
                    store.setPatcherId(patcherId);
                }
                else {
                    if (store.fileHash) {
                        if (patch.op === 'replace' || patch.op === 'add') {
                            const { dbFileRef } = store;

                            dbFileRef.set({ patcherId: store.patcherId });
                            dbFileRef.child(patch.path).set(patch.value);
                        }
                    }
                    else {
                        // nudge the fileName input box
                    }
                }
            }
        });
        */
    }

    writeData = (store, dbFileRef, data) => {
        const dbRef = dbFileRef ? dbFileRef : store.dbFileRef;
        getRoot(store).app.setWriteInProgress(true);
        dbRef.transaction(function (currentData) {
            return data;
        }, (error, committed, snapshot) => {
            if (error) {
                console.log('Transaction failed abnormally!', error);
            } else if (!committed) {
                console.log('No data is committed..');
            } else {
                getRoot(store).app.setWriteInProgress(false);
            }
        });
    }
    getFile = (fileId, callback) => {
        fetch(`${firebaseConfig.apiURL}/getFile?fileId=${fileId}&userEmail=${store.user.email}`, {
            method: 'GET',
            cors: 'no-cors',

        })
            .then(response => response.json())
            .then((res) => {
                console.log('getFile: ' + JSON.stringify(res));
                callback(null, res);
            })
            .catch((err) => {
                console.log('Err when getFile: ' + err);
                callback(err, null);
            });
    };

    getFBData = (cb) => {
        const fbDatabase = firebase.database().ref('/files');
        fbDatabase.once("value", function (snapshot) {
            const fbData = snapshot.val();
            if (cb) {
                cb(fbData);
            }
        }, function (errorObject) {
            console.log('The read failed: ' + errorObject.message);
        });
    }

    loadDoc = (store, user, opts) => {
        const urlParams = new URLSearchParams(decodeURI(window.location.search));
        const state = urlParams.get('state');
        const stateObj = opts || JSON.parse(state);


        const callDuplicate = (fileId, fileName, fbData, store, user) => {
            this.duplicate(fileId, fileName, fbData, store, user);
        };

        if (stateObj) {
            //this part can be moved out of this class if needed
            if (stateObj.action === 'create') {
                //create file
                this.callCreateFile(store, stateObj);
                store.addUser(user);
            } else if (stateObj.action === 'open') {
                console.log('loading')
                const fileId = stateObj.ids[0];
                this.getFBData((fbData) => {
                    if (!fbData) {
                        console.log('ERROR when getting firebase data');
                        return;
                    }
                    if (fbData.hasOwnProperty(fileId)) {
                        store.loadSnapshot(fbData[fileId]);
                        store.addUser(user);
                        store.updateLastActive();
                    } else {
                        //treated as duplicate
                        callDuplicate(fileId, '', fbData, store, user);

                    }

                }, function (errorObject) {
                    console.log('The read failed: ' + errorObject.message);
                });
            }
        }
    };

    duplicate(fileId, fileName, fbData, store, user, cb) {
        try {
            const callUpdateFileId = (cb) => {
                this.updateFileId(store, () => {
                    if (cb) {
                        cb();
                    }
                });
            };

            const callWriteData = (store, dbFileRef, data) => {
                this.writeData(store, dbFileRef, data);
            };

            this.getFile(fileId, function (err, res) {
                //get origFileId
                if (err) {
                    console.log('Error when getting file' + err);
                    return;
                }
                const {id, name, description} = res;
                const {origFileId} = JSON.parse(description);
                console.log('origFileId, name ' + origFileId + ' ' + name);
                if (!origFileId || !name) {
                    console.log('Error: no origFileId or name');
                    return;
                }
                if (fbData.hasOwnProperty(origFileId)) {
                    //set fbData
                    let fbDataObj = fbData[origFileId];
                    fbDataObj.fileId = id;
                    fbDataObj.fileName = fileName ? fileName : name.replace('.prgm', '');

                    const dbFileRef = firebase.database().ref(`/files/${id}`);
                    callWriteData(store, dbFileRef, fbDataObj);
                    store.loadSnapshot(fbDataObj);
                    store.addUser(user);

                    //update file property origFileId
                    callUpdateFileId(() => {
                        if (cb) {
                            cb();
                        }
                    });
                }

                store.updateLastActive();
            });
        } catch (err) {
            console.log('ERROR when Duplicating ' + err);
        }
    }


    callCreateFile(store, stateObj) {
        const callUpdateFileId = () => {
            this.updateFileId(store);
        };
        const reqParams = {
            fileName: store.fileName,
            userEmail: store.user.email
        };
        if (stateObj && stateObj.folderId) {
            reqParams['folderId'] = stateObj.folderId;
        }
        fetch(`${firebaseConfig.apiURL}/createFile`, {
            method: 'POST',
            cors: 'no-cors',
            body: JSON.stringify(reqParams),
        })
            .then(response => response.json())
            .then(function (res) {
                console.log(JSON.stringify(res));
                store.setFileId(res.id);
                store.setFileName(res.name.replace('.prgm', ''));

                //call updateFile to update origId
                callUpdateFileId();
                store.addUser(store.user);
            }).catch(function (err) {
            console.log('Err when creating: ' + err);
        });
    }


    updateFileId(store, cb) {
        // console.log('POSTING: '+store.getFileName());
        fetch(`${firebaseConfig.apiURL}/updateFile`, {
            method: 'POST',
            cors: 'no-cors',
            body: JSON.stringify({
                fileId: store.fileHash,
                fileName: store.getFileName(),
                userEmail: store.user.email,
                origFileId: store.fileHash

            }),
        })
            .then(response => response.json())
            .then((res) => {
                console.log('updated origFileId ' + JSON.stringify(res));
                if (cb) {
                    cb();
                }
            })
            .catch((err) => {
                console.log('Err when updating origFileId: ' + err);
            });
    }
}
