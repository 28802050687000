import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import {reaction} from "mobx";
import {applyToPoints, compose, rotateDEG} from 'transformation-matrix';
import Handle from './Handle';
import DrawArrow from "./DrawArrow";

import {
    combineBBoxes,
    convertPts,
    cos,
    degToRadian, distance, distToSegment,
    getLength,
    setHeightAndDeltaH,
    setWidthAndDeltaW,
    sin, stageToArea, stageToLinear
} from "../../utils";
import {undoManager} from "../../stores/RootStore";


@observer
class SelectionBox extends Component {
    constructor(props) {
        super(props);
        this.handleSize = 8;
        this.arrowHandleSize = 6;
        this.state = {
            height: 0,
            width: 0,
            x: 0,
            y: 0,
            rotation: 0,
            arrow: false
        };
        this.calculateBounds = true;
    }

    buildBoundingBox = () => {
        if (!this.calculateBounds) return;
        let bboxes = [];

        //for single selections we can use the rotated bounding box (it's neater)
        //but for multiple selections that can have different rotations, we need to treat this as a new
        //unrotated selection
        let selection = this.props.store.selection;
        if (selection.length === 1) {
            bboxes.push(selection[0].shape.box);
            this.setState({
                rotation: selection[0].shape.rotation
            });
        } else {
            selection.forEach((el) => {
                bboxes.push(el.shape.boundingBox);
            });
            this.setState({
                rotation: 0
            });
        }

        let {x, y, width, height} = combineBBoxes(bboxes);
        this.setState({x, y, width, height});
    };

    componentDidMount() {
        this.buildBoundingBoxReactionDisposer = reaction(
            () => this.props.store.selection.map(selection => selection.area),
            () => this.buildBoundingBox(), {
                fireImmediately: true
            }
        );
    }

    componentWillUnmount() {
        this.buildBoundingBoxReactionDisposer();
    }

    setCircleResize(pos, record) {
        const startPos = record.layout.shape.position;
        const radius = distance(startPos, pos) / Math.sqrt(2);//relationship of radius vs distance to handle in square
        const r = stageToLinear(radius);
        record.setTargetNet(Math.round(Math.PI * r * r));
    }

    convertPtsWithin(coords, record) {
        let svg = document.getElementsByClassName('stage-svg')[0].getElementsByTagName("svg")[0];
        let pt = svg.createSVGPoint();
        pt.x = coords.x;
        pt.y = coords.y;
        return pt.matrixTransform(record.SVGElement.getScreenCTM().inverse());
    }

    getBoundsResize(startingBounds, dragDeltaX, dragDeltaY, dir, lockAspect) {
        if (lockAspect) {
            switch (dir) {
                case 'nw':
                case 'se':
                    dragDeltaY = dragDeltaX * startingBounds.height / startingBounds.width;
                    break;
                case 'ne':
                case 'sw':
                    dragDeltaY = -dragDeltaX * startingBounds.height / startingBounds.width;
                    break;
            }

        }
        const bounds = {
            x: startingBounds.x,
            y: startingBounds.y,
            width: startingBounds.width,
            height: startingBounds.height,
        };
        switch (dir) {
            case 'nw':
                bounds.width -= dragDeltaX;//negative deltaX will grow width
                bounds.height -= dragDeltaY;
                bounds.x += dragDeltaX;
                bounds.y += dragDeltaY;
                break;
            case 'ne':
                bounds.width += dragDeltaX;
                bounds.height -= dragDeltaY;
                bounds.y += dragDeltaY;
                break;
            case 'sw':
                bounds.width -= dragDeltaX;//negative deltaX will grow width
                bounds.height += dragDeltaY;
                bounds.x += dragDeltaX;
                break;
            case 'se':
                bounds.width += dragDeltaX;
                bounds.height += dragDeltaY;
                break;
        }
        // console.log(dragDeltaX, bounds.width, startingBounds.width);

        return bounds;
    }

    getRectResize(dragDeltaX, dragDeltaY, rotateAngle, shapeWidth, shapeHeight, dir) {
        // https://github.com/mockingbot/react-resizable-rotatable-draggable
        let x = 0;
        let y = 0;

        let minHeight = 10;
        let minWidth = 10;

        let deltaL = getLength(dragDeltaX, dragDeltaY);
        let alpha = Math.atan2(dragDeltaY, dragDeltaX);
        let beta = alpha - degToRadian(rotateAngle);
        let deltaW = deltaL * Math.cos(beta);
        let deltaH = deltaL * Math.sin(beta);

        switch (dir) {
            case 'nw':
                deltaW = -deltaW;
                deltaH = -deltaH;
                break;
            case 'ne':
                deltaH = -deltaH;
                break;
            case 'sw':
                deltaW = -deltaW;
                break;
            case 'se':
        }

        let widthAndDeltaW = setWidthAndDeltaW(shapeWidth, deltaW, minWidth);
        deltaW = widthAndDeltaW.deltaW;
        let heightAndDeltaH = setHeightAndDeltaH(shapeHeight, deltaH, minHeight);
        deltaH = heightAndDeltaH.deltaH;
        let height = deltaH;
        let width = deltaW;

        switch (dir) {
            case 'nw':
                x = -(deltaW / 2 * cos(rotateAngle) - deltaH / 2 * sin(rotateAngle));
                y = -(deltaW / 2 * sin(rotateAngle) + deltaH / 2 * cos(rotateAngle));
                break;
            case 'ne':
                x = deltaW / 2 * cos(rotateAngle) + deltaH / 2 * sin(rotateAngle);
                y = deltaW / 2 * sin(rotateAngle) - deltaH / 2 * cos(rotateAngle);
                break;
            case 'sw':
                x = -(deltaW / 2 * cos(rotateAngle) + deltaH / 2 * sin(rotateAngle));
                y = -(deltaW / 2 * sin(rotateAngle) - deltaH / 2 * cos(rotateAngle));
                break;
            case 'se':
                x = deltaW / 2 * cos(rotateAngle) - deltaH / 2 * sin(rotateAngle);
                y = deltaW / 2 * sin(rotateAngle) + deltaH / 2 * cos(rotateAngle);
                break;
        }
        return {
            incrementSize: {width, height},
            incrementPosition: {x, y}
        };
    }

    resizePolyCorner = (e, dir) => {
        const {store} = this.props;
        const {selectedRecords} = store;

        if (selectedRecords.length !== 1) return;
        const [record] = selectedRecords;
        e.stopPropagation();
        if (record.shape.type === 'rect') {
            record.setShape('polygon');
        }
        //TODO if all control points are cleared, record.setShape('rect');

        undoManager.startGroup(() => {
            e.stopPropagation();
            const {shape} = record;
            const normPos = (e) => {
                let pt = this.convertPtsWithin({x: e.clientX, y: e.clientY}, record);

                return {
                    x: pt.x / shape.width,
                    y: pt.y / shape.height
                };
            };
            const onMove = (e) => {
                const norm = normPos(e);
                shape.setControlPoint('ortho', dir, norm.x, norm.y);
            };

            const onUp = (e) => {
                const tol = 0.05;
                const norm = normPos(e);

                if (dir === 'nw' && norm.x < tol && norm.y < tol) {
                    shape.clearControlPoint(dir);
                }
                if (dir === 'ne' && (1 - norm.x) < tol && norm.y < tol) {
                    shape.clearControlPoint(dir);
                }
                if (dir === 'se' && (1 - norm.x) < tol && (1 - norm.y) < tol) {
                    shape.clearControlPoint(dir);
                }
                if (dir === 'sw' && norm.x < tol && (1 - norm.y) < tol) {
                    shape.clearControlPoint(dir);
                }
                undoManager.stopGroup();
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp)
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
        });
    };


    movePolyHandle = (e, handle) => {
        const {store} = this.props;
        const {selectedRecords} = store;

        if (selectedRecords.length !== 1) return;
        const [record] = selectedRecords;
        e.stopPropagation();

        const normPos = (e) => {
            const {shape} = record;

            let pt = this.convertPtsWithin({x: e.clientX, y: e.clientY}, record);

            return {
                x: pt.x / shape.width,
                y: pt.y / shape.height
            };
        };

        //as soon as we grab a mid-point polygon handle, we transform all handle types to 'vertex' rather than 'ortho'
        record.shape.convertToVertexControlPoints();

        if (handle.grab) {
            const pos = normPos(e);
            handle.grab(record.shape, pos.x, pos.y);//inserts a point if needed
        }

        //TODO if all control points are cleared, record.setShape('rect');

        undoManager.startGroup(() => {
            e.stopPropagation();
            const {shape} = record;

            const onMove = (e) => {
                const norm = normPos(e);
                shape.setControlPoint('vertex', handle.id, norm.x, norm.y);
            };

            const onUp = (e) => {
                const tol = 0.05;
                const norm = normPos(e);

                undoManager.stopGroup();
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp)
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
        });
    };

    resizeShape = (e, dir) => {
        const {store} = this.props;
        const {selectedRecords, stage} = store;

        undoManager.startGroup(() => {
            e.stopPropagation();

            let convertedPt = convertPts({x: e.clientX, y: e.clientY});
            let startX = convertedPt.x;
            let startY = convertedPt.y;

            //when we resize shapes it's not a perfect matrix transform as it would be in Illustrator
            //because rectangles must remain orthogonal even if we squish the box
            //as a reasonable compromise, we use the relative bounding boxes (at the start) to apply mini transforms to all
            const recordBoundsRatios = {};
            let startingBounds = {};
            if (selectedRecords.length > 1) {
                this.calculateBounds = false;//can't reliably use calculated bounding boxes while resizing (given ortho requirement)
                let bboxes = [];
                selectedRecords.forEach(record => {
                    bboxes.push(record.shape.boundingBox);
                });
                const combinedRect = combineBBoxes(bboxes);

                selectedRecords.forEach(record => {
                    const bb = record.shape.boundingBox;
                    recordBoundsRatios[record.id] = {
                        x: (bb.x - combinedRect.x) / combinedRect.width,
                        y: (bb.y - combinedRect.y) / combinedRect.height,
                        width: bb.width / combinedRect.width,
                        height: bb.height / combinedRect.height,
                    };
                });
                startingBounds = combinedRect;
            }
            const onMove = (e) => {
                let convertedPt = convertPts({x: e.clientX, y: e.clientY});

                let dragDeltaX = convertedPt.x - startX;
                let dragDeltaY = convertedPt.y - startY;
                if (selectedRecords.length === 1) {
                    if (stage.activeLayout !== 'plan') {
                        selectedRecords.forEach(record => {
                            this.setCircleResize(convertedPt, record, dir);
                        });
                        return;
                    }
                    //NOTE: single rotated bounds uses incremental changes, whereas multiple uses absolute
                    const [record] = selectedRecords;
                    let rotateAngle = record.shape.rotation;
                    let shapeHeight = record.height;
                    let shapeWidth = record.width;
                    const {incrementSize, incrementPosition} = this.getRectResize(dragDeltaX, dragDeltaY, rotateAngle, shapeWidth, shapeHeight, dir);
                    record.layout.incrementSize(incrementSize.width, incrementSize.height);
                    record.layout.incrementPosition(incrementPosition.x, incrementPosition.y);
                    this.buildBoundingBox();
                    startX = convertedPt.x;
                    startY = convertedPt.y;
                } else {
                    const newBounds = this.getBoundsResize(startingBounds, dragDeltaX, dragDeltaY, dir, true);
                    this.setState(newBounds);
                    selectedRecords.forEach(record => {
                        const {x, y, width, height} = recordBoundsRatios[record.id];
                        const shapeBounds = {
                            x: newBounds.x + x * newBounds.width,
                            y: newBounds.y + y * newBounds.height,
                            width: newBounds.width * width,
                            height: newBounds.height * height,
                        };
                        record.layout.shape.setBounds(shapeBounds);
                        if (stage.activeLayout !== 'plan') {
                            record.setTargetNet(stageToArea(record.layout.shape.area));
                        }
                    });
                }


            };

            const onUp = () => {
                this.calculateBounds = true;
                undoManager.stopGroup();
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp)
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
        });
    };

    handleRotation = (e) => {
        const {store} = this.props;
        const {selectedRecords} = store;

        undoManager.startGroup(() => {
            e.stopPropagation();
            let originalRotation = this.state.rotation;

            selectedRecords.forEach(record => {
                record.setRotating(true);
            });

            //zero rotation equates to the handle being at the top right corner, so we use this as the starting vector
            const handleZero = {x: this.state.x + this.state.width, y: this.state.y};

            const center = {
                x: this.state.x + this.state.width / 2,
                y: this.state.y + this.state.height / 2
            };
            const startVector = {
                x: handleZero.x - center.x,
                y: handleZero.y - center.y
            };

            const onMove = (e) => {
                this.setRotation(startVector, e);
                selectedRecords.forEach(record => {
                    let rotation = this.state.rotation - originalRotation;

                    let matrix = compose(
                        rotateDEG(rotation, this.state.x + this.state.width / 2, this.state.y + this.state.height / 2)
                    );

                    let coords = applyToPoints(matrix, [[record.x, record.y], [record.x, record.y + record.height]]);
                    let rads = Math.atan((coords[0][0] - coords[1][0]) / (coords[0][1] - coords[1][1]));
                    let degs = (rads * (180 / Math.PI)) * -1;

                    record.shape.setRotation(degs);
                    record.shape.incrementPosition(coords[0][0] - record.x, coords[0][1] - record.y);
                });
                originalRotation = this.state.rotation;
            };

            const onUp = () => {
                undoManager.stopGroup();
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp);
                selectedRecords.forEach(record => {
                    record.setRotating(false);
                });
                this.buildBoundingBox();//rebuild ortho - this ensures consistent behavior, but could be annoying to have rotation handle move
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
        });
    };

    setRotation = (startVector, e) => {
        const getAngle = ({x: x1, y: y1}, {x: x2, y: y2}) => {
            const dot = x1 * x2 + y1 * y2;
            const det = x1 * y2 - y1 * x2;
            const angle = Math.atan2(det, dot) / Math.PI * 180;
            return (angle + 360) % 360
        };
        const center = {
            x: this.state.x + this.state.width / 2,
            y: this.state.y + this.state.height / 2
        };
        const convertedPt = convertPts({x: e.clientX, y: e.clientY});
        const rotateVector = {
            x: convertedPt.x - center.x,
            y: convertedPt.y - center.y
        };
        const angle = getAngle(startVector, rotateVector);
        this.setState({
            rotation: angle
        })
    };

    arrowHandleOver = (e) => {
        e.target.setAttribute('r', `${(this.arrowHandleSize + 2) / this.props.store.stage.scale}`);
    };

    arrowHandleOut = (e) => {
        e.target.setAttribute('r', `${this.arrowHandleSize / this.props.store.stage.scale}`);
    };

    dragConnection = (e) => {
        e.stopPropagation();
        this.setState({
            arrow: true
        });

        const onUp = (e) => {
            document.removeEventListener('mouseup', onUp);
            this.setState({
                arrow: false
            });

            let activeRecord = this.props.store.activeRecords[0];
            if (activeRecord) {
                this.props.store.addArrow(this.props.store.selectedRecords[0], activeRecord)
            }
        };

        document.addEventListener('mouseup', onUp);
    };

    render() {
        const {store} = this.props;
        const {stage} = store;
        const {scale} = stage;

        const {selectedRecords} = store;
        if (selectedRecords.length < 1) return null;

        let padding = 4;

        let handleSize = this.handleSize / scale;
        handleSize = handleSize > 2 ? handleSize : 2;

        let arrowHandleSize = this.arrowHandleSize / scale;
        arrowHandleSize = arrowHandleSize > 2 ? arrowHandleSize : 2;

        let rotationHandleSize = {
            width: 25 * (1 / stage.matrixValue.a),
            height: 25 * (1 / stage.matrixValue.a),
            y: -15 * (1 / stage.matrixValue.a),
            fontSize: 20 * (1 / stage.matrixValue.a)
        };

        const handleOffset = handleSize * 2.5;
        const polyHandleOpacity = 0.65;
        const unusedHandleOpacity = 0.15;

        const showPolyHandles = stage.activeLayout === 'plan' && store.selection.length === 1 && Math.min(this.state.width, this.state.height) > handleOffset * 5;

        const [record] = selectedRecords;
        const {shape} = record;
        const showOrthoHandles = shape.type === 'rect' || shape.controlPointType === 'ortho';

        const polyHandleX = (dir, xPos) => {
            if (shape.controlPoints && shape.controlPoints.has(dir)) {
                return this.state.width * shape.controlPoints.get(dir).x;
            }
            return this.state.width * xPos + handleOffset * (1 - 2 * xPos)
        };
        const polyHandleY = (dir, yPos) => {
            if (shape.controlPoints && shape.controlPoints.has(dir)) {
                return this.state.height * shape.controlPoints.get(dir).y;
            }
            return this.state.height * yPos + handleOffset * (1 - 2 * yPos)
        };

        const handles = (dir, xPos, yPos) => {

            const handlePos = {
                x: this.state.width * xPos - handleSize / 2,
                y: this.state.height * yPos - handleSize / 2,
            };
            handlePos.x += (xPos - 0.5) * handleSize;
            handlePos.y += (yPos - 0.5) * handleSize;
            return <Fragment>
                <Handle
                    className={dir}
                    mouseDown={(e) => this.resizeShape(e, dir)}
                    mouseUp={this.cancelResize}
                    x={handlePos.x}
                    y={handlePos.y}
                    size={handleSize}/>
                {showOrthoHandles && showPolyHandles && <Handle
                    className={dir}
                    shape={'circle'}
                    opacity={polyHandleOpacity}
                    mouseDown={(e) => this.resizePolyCorner(e, dir)}
                    mouseUp={this.cancelResize}
                    x={polyHandleX(dir, xPos)}
                    y={polyHandleY(dir, yPos)}
                    size={handleSize}/>}
            </Fragment>
        };

        const renderInBetweenHandles = () => {
            if (shape.type !== 'polygon') return null;
            const handlePositions = [];
            let vertices = shape.sizedVertices.map(v => {
                return {
                    id: v.linkId,
                    x: v.x - handleSize / 2,
                    y: v.y - handleSize / 2,
                }
            });
            vertices.forEach((v, i) => {
                handlePositions.push({
                    id: v.id,
                    opacity: shape.controlPointType === 'ortho' ? unusedHandleOpacity : polyHandleOpacity,
                    x: v.x,
                    y: v.y,
                });
                let nextHandle = i + 1;
                if (nextHandle >= vertices.length) {
                    nextHandle = 0;//wrap
                }

                let handlePosition = {
                    id: 'mid_' + i,//TEMPORARY ID. Actual Id is created in insertControlPoint and set in grab function below
                    grab: (shape, x, y) => {
                        const cp = shape.insertControlPoint(i + 1, x, y);
                        handlePosition.id = cp.id;
                    },
                    opacity: unusedHandleOpacity,
                    x: (v.x + vertices[nextHandle].x) / 2,
                    y: (v.y + vertices[nextHandle].y) / 2,
                };
                handlePositions.push(handlePosition);
            });
            return <Fragment>
                {handlePositions.map((h) =>
                    <Handle
                        key={h.id}
                        className={'mid'}
                        shape={'square'}
                        opacity={h.opacity}
                        mouseDown={(e) => this.movePolyHandle(e, h)}
                        mouseUp={this.cancelResize}
                        x={h.x}
                        y={h.y}
                        size={handleSize}/>
                )}
            </Fragment>;
        }

        const renderHandles = () => {
            return <Fragment>
                {showPolyHandles && renderInBetweenHandles()}
                {handles('nw', 0, 0)}
                {handles('ne', 1, 0)}
                {handles('sw', 0, 1)}
                {handles('se', 1, 1)}
            </Fragment>
        }

        return (
            <g className={"SelectionBox"}>
                {this.state.arrow &&
                <DrawArrow
                    x1={store.selection[0].x}
                    y1={store.selection[0].y}
                    x2={store.stage.mousePosition.x}
                    y2={store.stage.mousePosition.y}
                />}

                <g transform={`translate(${this.state.x}, ${this.state.y})`}>
                    <g transform={`rotate(${this.state.rotation}, ${this.state.width / 2}, ${this.state.height / 2}) ${(stage.activeLayout === 'plan') ? `translate(0,0)` : ''}`}>


                        <line x1={-padding} y1={-padding} x2={this.state.width + padding} y2={-padding} stroke="blue"
                              strokeDasharray="4"/>
                        <line x1={-padding} y1={-padding} x2={-padding} y2={this.state.height + padding} stroke="blue"
                              strokeDasharray="4"/>
                        <line x1={this.state.width + padding} y1={-padding} x2={this.state.width + padding}
                              y2={this.state.height + padding} stroke="blue" strokeDasharray="4"/>
                        <line x1={-padding} y1={this.state.height + padding} x2={this.state.width + padding}
                              y2={this.state.height + padding} stroke="blue" strokeDasharray="4"/>

                        {/* Rotate handle*/}
                        {stage.activeLayout === 'plan' &&
                        <g>
                            <foreignObject
                                width={rotationHandleSize.width > 20 ? rotationHandleSize.width : 20}
                                height={rotationHandleSize.height > 20 ? rotationHandleSize.height : 20}
                                x={this.state.width}
                                y={rotationHandleSize.y < -15 ? rotationHandleSize.y : -15}
                                className={"rotationHandle"}
                                onMouseDown={this.handleRotation}
                            >
                                <div
                                    style={{fontSize: rotationHandleSize.fontSize > 15 ? rotationHandleSize.fontSize : 15}}
                                    className="retina-design-0761">
                                </div>
                            </foreignObject>
                        </g>}

                        {renderHandles()}

                        {stage.activeLayout === 'adjacency' && store.selection.length === 1 &&
                        <g>
                            <defs>
                                <filter id="shadow">
                                    <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.6"/>
                                </filter>
                            </defs>

                            <circle
                                className={"arrowHandle"}
                                r={arrowHandleSize}
                                fill={"#441cc1"}
                                cx={this.state.width / 2}
                                cy={-(arrowHandleSize + 10)}
                                onMouseOver={this.arrowHandleOver}
                                onMouseOut={this.arrowHandleOut}
                                onMouseDown={this.dragConnection}
                            />

                            <circle
                                className={"arrowHandle"}
                                r={arrowHandleSize}
                                fill={"#441cc1"}
                                cx={this.state.width + arrowHandleSize + 10}
                                cy={this.state.height / 2}
                                onMouseOver={this.arrowHandleOver}
                                onMouseOut={this.arrowHandleOut}
                                onMouseDown={this.dragConnection}
                            />

                            <circle
                                className={"arrowHandle"}
                                r={arrowHandleSize}
                                fill={"#441cc1"}
                                cx={this.state.width / 2}
                                cy={this.state.height + arrowHandleSize + 10}
                                onMouseOver={this.arrowHandleOver}
                                onMouseOut={this.arrowHandleOut}
                                onMouseDown={this.dragConnection}
                            />

                            <circle
                                className={"arrowHandle"}
                                r={arrowHandleSize}
                                fill={"#441cc1"}
                                cx={-(arrowHandleSize + 10)}
                                cy={this.state.height / 2}
                                onMouseOver={this.arrowHandleOver}
                                onMouseOut={this.arrowHandleOut}
                                onMouseDown={this.dragConnection}
                            />
                        </g>
                        }
                    </g>
                </g>
            </g>
        );
    }
}

export default SelectionBox;
