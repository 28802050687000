import React, { Component } from 'react';
import {observer} from "mobx-react";

import GooglePicker from "../GooglePicker";

@observer
class IntroDialog extends Component {

    openExisting=()=>{
        new GooglePicker(this.props.store);
    }

    createNew=()=>{
        const {store, fbModel, closeModal} = this.props;
        fbModel.callCreateFile(store.persistStore, null);
        closeModal();
    }

    checkOpenState(){
        const {store, fbModel, closeModal} = this.props;
        const urlParams = new URLSearchParams(decodeURI(window.location.search));
        const state = urlParams.get('state');
        const stateObj = JSON.parse(state);
        if (stateObj) {
            if (stateObj.action === 'open') {
                closeModal();
            }
        }
    }

    render() {
        const {store, fbModel, closeModal} = this.props;
        this.checkOpenState();
        return (
            <div className="IntroModal">
                <div className="content">
                    <h1>Programming Tool</h1>
                    <span>
                        By Sasaki
                    </span>

                    <p className="copy">
                        This product allows you to create different phases of your programming process by assigning relationships and sketching in plan. Get started below.
                    </p>


                    <button className="startButton block ss-button nl" onClick={this.openExisting}>
                        Open Existing
                    </button>
                    <p>
                        This will take you to the root of your Google Drive.
                    </p>
                    <button className="startButton block ss-button primary" onClick={ ()=>{
                        this.createNew();
                    }}>
                        Create New
                    </button>
                    <p>
                        This will create a new file at the root of your Google Drive.
                    </p>
                </div>

                <div className="new">
                    <h2>What's New</h2>
                    <p>
                        Access your prgm files for the Programming tool through Google Drive
                    </p>
                    <p className="error">
                        Multi-user mode is experiencing issues that we are currently fixing. Please limit to one user editing a document at a time.
                    </p>
                </div>
            </div>
        );
    }
}

export default IntroDialog;
