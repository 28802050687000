import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import {observer} from "mobx-react";

import {undoManager} from "../../stores/RootStore";

import PlanShape from "./PlanShape";
import Circle from "./Circle";
import Tooltip from "./Tooltip";
import AdjacencyView from "./AdjacencyView";

const appRoot = document.getElementById('app-root');

@observer
class Shape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editLabel: false
        };
    }

    componentDidMount() {
        if (this.props.element.editingName) {
            this.handleEditingName();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.textEditCancel);
    }

    handleMouseOver = (e) => {
        const {store} = this.props;
        store.activeRecords.forEach((r, i) => {//ensure only one active record on plan (otherwise they get 'stuck' in that state)
            r.setActive(r.id === this.props.element.id);
        });
        this.props.element.setActive(true);
    };

    handleMouseOut = (e) => {
        this.props.element.setActive(false);
    };

    handleMouseClick = (e) => {
        e.stopPropagation();
    };

    handleMouseDown = (e) => {
        const store = this.props.store;
        const stage = store.stage;
        if (stage.activeTool === 'pan') {
            undoManager.startGroup(() => {
                e.stopPropagation();
                stage.setDragging(true);
                if (!this.props.element.isSelected && !e.ctrlKey) {
                    store.clearSelected();
                }
                store.setSelected(this.props.element);
                if (e.altKey) {
                    store.selectedRecords.forEach(record => {
                        store.copyRecord(record);
                    });
                }

                let startX = stage.mousePosition.x;
                let startY = stage.mousePosition.y;

                const onMove = (e) => {
                    store.selectedRecords.forEach(record => {
                        if (!record.layout.isPlaced) {
                            record.layout.markPlaced();
                        }
                        record.layout.incrementPosition(stage.mousePosition.x - startX, stage.mousePosition.y - startY);
                    });
                    startX = stage.mousePosition.x;
                    startY = stage.mousePosition.y;
                };

                const onUp = () => {
                    undoManager.stopGroup();
                    this.props.store.stage.setDragging(false);
                    document.removeEventListener('mousemove', onMove);
                    document.removeEventListener('mouseup', onUp)
                };

                document.addEventListener('mousemove', onMove);
                document.addEventListener('mouseup', onUp);
            });
        }
    };

    handleEditingName = () => {
        this.props.element.setEditingName(true);
        this.setState({
            editLabel: true
        });
        document.addEventListener('mouseup', this.textEditCancel);
    };

    textEditCancel = (e) => {
        this.props.element.setEditingName(false);
        if (e.target.type === "textarea") return;
        this.setState({
            editLabel: false
        });
        document.removeEventListener('mouseup', this.textEditCancel);
    };

    handleKeyPress = (e) => {

    };

    editName = (val) => {
        this.props.element.setName(val);
    };

    render() {
        const {element, store, exportMode} = this.props;
        const {shape} = element;
        const globalTranslateString = `translate(${element.x}, ${element.y})`;

        if (exportMode) {
            return (
                <g transform={globalTranslateString}>
                    {(shape.type === 'rect' || shape.type === 'polygon') &&
                    <PlanShape
                        store={store}
                        exportMode={exportMode}
                        element={element}
                    />
                    }
                    {shape.type === 'circle' &&
                    <Circle
                        store={store}
                        exportMode={exportMode}
                        element={element}
                    />}
                </g>
            );
        }

        return (
            <g className={`Shape ${element.isActive ? 'Shape--active' : ''}`} transform={globalTranslateString}>
                {(shape.type === 'rect' || shape.type === 'polygon') && (
                    <Fragment>
                        <PlanShape
                            store={store}
                            element={element}
                            handleMouseDown={this.handleMouseDown}
                            handleEditingName={this.handleEditingName}
                            handleMouseClick={this.handleMouseClick}
                            handleMouseOver={this.handleMouseOver}
                            handleMouseOut={this.handleMouseOut}
                            handleKeyPress={this.handleKeyPress}
                            editName={this.editName}
                            editLabel={this.state.editLabel}
                        />
                        {element.isActive && !store.stage.marqueeSelect && ReactDOM.createPortal(<Tooltip store={store} element={element}/>, appRoot)}
                    </Fragment>
                )}
                {shape.type === 'circle' &&
                <Circle
                    store={store}
                    element={element}
                    handleMouseDown={this.handleMouseDown}
                    handleEditingName={this.handleEditingName}
                    handleMouseClick={this.handleMouseClick}
                    handleMouseOver={this.handleMouseOver}
                    handleMouseOut={this.handleMouseOut}
                    handleKeyPress={this.handleKeyPress}
                    editName={this.editName}
                    editLabel={this.state.editLabel}
                />}
            </g>
        );
    }
}

Shape.defaultProps = {
    exportMode: false,
};
export default Shape;
