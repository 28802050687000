import React from 'react';
import { observer } from 'mobx-react';

const User = ({ photoURL, name, away }) => (
    <li className={`active-user ${away ? 'active-user--away':''}`}>
        <div className="active-user__photo">
            <img src={photoURL} />
        </div>

        <div className="active-user__info">
            <p>{name}</p>
        </div>
    </li>
);


const ActiveUsers = ({ store }) => (
    <div className={`ActiveUsers ${store.persistStore.activeUsers.length > 1 ? "errored" : ''}`}>
        {store.persistStore.activeUsers.length > 1 ? (
            <h5>Multiple users in document. Your changes may be overwritten.</h5>
        ) : (
            <h5>Active Online Users</h5>
        )}

        <ul>
            {store.persistStore.activeUsers.map(user => <User {...user} key={user.name} />)}
            {store.persistStore.awayUsers.map(user => <User away={true} {...user} key={user.name} />)}
        </ul>
    </div>
);


export default observer(ActiveUsers);
