import {applySnapshot, getRoot, types} from "mobx-state-tree";
import {Record} from "./RecordStore";
import ProjectStore from "./ProjectStore";
import firebase from "firebase";
import {Arrow} from "../models/Arrow";
import {ParentDepartment} from "../models/ParentDepartment";
import {v4} from "node-uuid";


const FIVE_MINUTES = 5 * 60 * 1000;
const TEN_MINUTES = 10 * 60 * 1000;
let t;

const UserItem = types
    .model('UserItem', {
        email: types.string,
        lastActive: types.optional(types.number, () => Date.now()),
        lastModified: types.optional(types.number, 0),
        name: types.string,
        photoURL: types.string,
    })
    .views(self => ({
        get lastModifiedDate() {
            return new Date(self.lastModified);
        },
    }))
    .actions(self => ({
        setLastActive(time) {
            self.lastActive = time;
        },
        setLastModified(time) {
            self.lastModified = time;
        },
    }));

export const PersistStore = types
    .model('PersistStore', {
        fileId: types.optional(types.string, ''),
        fileName: types.optional(types.string, 'untitled'),
        records: types.array(Record),
        arrows: types.array(Arrow),
        project: types.optional(ProjectStore, {}),
        imageName: types.optional(types.string, ""),
        imageURL: types.optional(types.string, ""),
        imageSize: types.array(types.number),
        imageScale: 1,
        userCollection: types.array(UserItem),
        parentDepartments: types.array(ParentDepartment)
    })
    .volatile(self => ({
        currentTime: Date.now(),
    }))
    .views(self => ({
        get activeUsers() {
            return self.userCollection.filter(user => self.currentTime - user.lastActive < FIVE_MINUTES);
        },
        get fileHash() {
            return self.fileId;
        },
        get dbFileRef() {
            return firebase.database().ref(`/files/${self.fileHash}`);
        },
        get awayUsers() {
            return self.userCollection.filter(user => self.currentTime - user.lastActive >= FIVE_MINUTES && self.currentTime - user.lastActive < TEN_MINUTES);
        },
        get user() {
            return getRoot(self).user;
        },
        get userItem() {
            return (self.userCollection.filter(user => self.user.email === user.email) || [])[0];
        },
        getFileName() {
            return self.fileName;
        },
        getFileNameWithExtension() {
            return self.fileName + '.prgm'
        },
        getFileId() {
            return self.fileId;
        },
    }))
    .actions(self => ({
        setImage(name, url, widthHeightArray) {
            self.imageName = name;
            self.imageURL = url;
            self.imageSize = widthHeightArray;
        },
        addParentDepartment(name){
            const dept = ParentDepartment.create({
                id: v4(),
                name: name
            });
            self.parentDepartments.push(dept);
            return dept;
        },
        setImageScale(scale) {
            self.imageScale = scale;
        },
        setFileId(fileId = '') {
            self.fileId = fileId;
        },
        setFileName(fileName) {
            self.fileName = fileName;
        },
        loadSnapshot(val) {
            applySnapshot(self, val);
        },
        setUser(user) {
            getRoot(self).setUser(user);
            self.updateLastActive();
        },
        addUser(user) {
            if (self.userCollection) {
                if (self.userCollection.length < 1) {
                    if (user) {
                        self.userCollection.push({
                            email: user.email,
                            name: user.displayName,
                            photoURL: user.photoURL,
                        });
                    }
                } else {
                    let userExists = false;
                    for (let userObj of self.userCollection) {
                        if (userObj.email === user.email) {
                            userExists = true;
                            break;
                        }
                    }
                    if (!userExists) {
                        self.userCollection.push({
                            email: user.email,
                            name: user.displayName,
                            photoURL: user.photoURL,
                        })
                    }
                }
            }
        },
        updateCurrentTime() {
            self.currentTime = Date.now();
        },
        updateLastActive() {
            if (!self.userItem) {
                if (self.user) {
                    self.addUser(self.user);
                    self.updateLastActive();
                }
            }
            else {
                self.userItem.setLastActive(Date.now());
            }

        },
    }));
