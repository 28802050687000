import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import {AutoSizer} from "react-virtualized";
import {ReactSVGPanZoom} from "react-svg-pan-zoom";

import PlanView from "./PlanView";
import AdjacencyView from "./AdjacencyView";

import {toJS} from "mobx";
import {convertPts, distance} from "../../utils";
import Tooltip from "./Tooltip";

@observer
class Stage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matrix: this.props.store.stage.matrixPos.get(this.props.layout)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.layout !== this.props.layout) {
            this.setState({
                matrix: this.props.store.stage.matrixPos.get(this.props.layout)
            })
        }
    }

    changeTool = (nextTool) => {
        this.props.store.stage.setTool(nextTool);
    };

    changeValue(nextValue) {
        let newVal = Object.assign({}, nextValue);
        this.setState({
            matrix: newVal
        });
        this.props.store.stage.setMatrixPos(newVal);
    }

    onMouseDown = (e) => {
        const store = this.props.store;
        const stage = store.stage;
        let mouseCoords = {x: e.clientX, y: e.clientY};
        let coords = convertPts(mouseCoords);
        stage.setMouseClickPosition(mouseCoords.x, mouseCoords.y);
        stage.setClickPosition(coords.x, coords.y);
        if (stage.activeTool === 'shape') {
            stage.setDrawing(true);
        }
        if (stage.activeTool === 'pan' && e.shiftKey) {
            this.changeTool('none');
            stage.setMarqueeSelect(true);
        }
    };

    onMouseClick = (e) => {
        const tol = 1;
        const store = this.props.store;
        const stage = store.stage;
        const coords = {x: e.clientX, y: e.clientY};

        if (stage.activeTool === 'pan') {
            //Note that a pan operation will result in the mouse moving, but the stage position not changing
            //we want a regular click to change selection - but not a pan (click and drag)
            const dist = distance(stage.mouseClickPosition, coords);
            if (dist < tol) {
                store.clearSelected();
            }
        } else if (stage.activeTool === 'scale' && !stage.hasScaleCoords) {
            stage.addToolData(convertPts(coords));
        }
    };

    onMouseUp = (e) => {
        const stage = this.props.store.stage;
        stage.setDrawing(false);
        stage.setMarqueeSelect(false);
        if (stage.activeTool !== 'scale') {
            this.changeTool('pan');
        }
        // if (e.ctrlKey) {
        //     this.downloadSvg();
        // }
    };

    onMouseMove = (e) => {
        const stage = this.props.store.stage;
        let coords = convertPts({x: e.clientX, y: e.clientY});
        stage.setMousePosition(coords.x, coords.y);
    };

    svgDefs() {
        return <defs>
            <marker className="arrowhead-unselected" id={`arrowhead-unselected`} markerWidth="10"
                    markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7"/>
            </marker>
            <marker className="arrowhead-selected" id={`arrowhead-selected`} markerWidth="10"
                    markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7"/>
            </marker>
        </defs>
    }

    renderPlainSvg() {
        return <svg width={2000} height={1000}>
            {this.svgDefs()}
            <g>
                {{
                    'adjacency': <AdjacencyView exportMode={true} store={store}/>,
                    'plan': <PlanView exportMode={true} store={store}/>,
                }[this.props.layout]}
            </g>
        </svg>;
    }

    render() {
        const {store, exportMode} = this.props;
        if (exportMode) return this.renderPlainSvg();
        const {activeTool} = this.props.store.stage;
        const tools = {
            'pan': 'pan',
            'arrow': 'none'
        };
        const activeToolPanZoom = (Object.keys(tools).includes(activeTool) ? tools[activeTool] : 'none');
        return (
            <div id={'stage'} className={"Stage " + activeTool}
                 tabIndex="0"
                 onMouseMove={this.onMouseMove}
                 onMouseDown={this.onMouseDown}
                 onMouseUp={this.onMouseUp}
                 onClick={this.onMouseClick}
            >
                <AutoSizer>
                    {(({width, height}) => width === 0 || height === 0 ? null : (
                        <ReactSVGPanZoom
                            ref={viewer => store.stage.setViewer(viewer)}
                            width={width} height={height}
                            tool={activeToolPanZoom} onChangeTool={tool => this.changeTool(tool)}
                            value={this.state.matrix} onChangeValue={value => this.changeValue(value)}
                            toolbarProps={{position: 'none'}}
                            miniatureProps={{position: 'none'}}
                            detectAutoPan={false}
                            preventPanOutside={false}
                            scaleFactorMin={0.05}
                            scaleFactorMax={25}
                            SVGBackground={"#f2f5fa"}
                            background={"#f2f5fa"}
                            scaleFactorOnWheel={1.3}
                            className={"stage-svg"}
                        >
                            <svg
                                width={2000}
                                height={1000}
                            >
                                {this.svgDefs()}
                                <g id="scale-wrapper">

                                    {{
                                        'adjacency': <AdjacencyView store={store}/>,
                                        'plan': <PlanView store={store}/>,
                                    }[this.props.layout]}
                                </g>
                            </svg>
                        </ReactSVGPanZoom>
                    ))}
                </AutoSizer>
            </div>
        )
    }
}

Stage.defaultProps = {
    exportMode: false,
};

export default Stage;
