import React, {Component} from 'react';
import {observer} from "mobx-react";
import ReactTooltip from "react-tooltip";
import ActiveUsers from './ActiveUsers';

@observer
class MenuBar extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            showActiveUsers: false,
        };
    }

    render() {
        const {store} = this.props;
        const userCount = store.persistStore.activeUsers.length;// + store.persistStore.awayUsers.length;

        return (
            <div className="MenuBar">
                <div className="container">
                    <div className="title">
                        <h4>Programming Tool</h4>
                    </div>

                    <div className="menu-items hz-tb">
                        <div className="filename">
                            {this.props.store.writeInProgress &&
                            <svg
                                data-tip="Saved to Google Drive"
                                data-place="bottom"
                                xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                <path d="M 17.032 8.348 L 17.032 7.944 C 17.03 4.283 14.061 1.316 10.4 1.316 L 10.352 1.316 C 7.243 1.315 4.553 3.479 3.888 6.516 C 1.725 6.694 0.057 8.497 0.048 10.668 L 0.048 10.8 C 0.047 11.91 0.487 12.974 1.271 13.76 C 2.055 14.545 3.118 14.987 4.228 14.988 L 7.068 14.988 L 7.068 14.248 L 4.228 14.248 C 2.331 14.239 0.798 12.697 0.8 10.8 L 0.8 10.66 C 0.807 8.768 2.336 7.233 4.228 7.22 L 4.54 7.22 L 4.592 6.916 C 5.083 4.109 7.518 2.06 10.368 2.056 L 10.4 2.056 C 11.964 2.053 13.465 2.672 14.573 3.776 C 15.68 4.881 16.303 6.38 16.304 7.944 L 16.304 8 C 16.304 8.225 16.29 8.449 16.264 8.672 L 16.216 9.072 L 16.768 9.072 C 18.145 9.074 19.26 10.191 19.26 11.568 L 19.26 11.752 C 19.26 13.128 18.144 14.244 16.768 14.244 L 13.72 14.244 L 13.72 14.984 L 16.768 14.984 C 18.55 14.98 19.993 13.538 20 11.756 L 20 11.572 C 20 9.889 18.709 8.487 17.032 8.348 Z M 10.724 10.272 C 10.684 10.229 10.633 10.199 10.576 10.184 C 10.435 10.1 10.256 10.12 10.136 10.232 L 7.924 12.4 C 7.78 12.545 7.78 12.779 7.924 12.924 C 7.994 12.995 8.089 13.035 8.188 13.036 C 8.286 13.036 8.379 12.997 8.448 12.928 L 10.028 11.38 L 10.028 18.312 C 10.013 18.453 10.08 18.591 10.201 18.666 C 10.322 18.741 10.474 18.741 10.595 18.666 C 10.716 18.591 10.783 18.453 10.768 18.312 L 10.768 11.364 L 12.368 13 C 12.438 13.071 12.533 13.111 12.632 13.112 C 12.78 13.11 12.912 13.02 12.969 12.884 C 13.026 12.748 12.998 12.591 12.896 12.484 Z" fill="rgb(68, 28, 193)"></path>
                            </svg>}
                            {!this.props.store.writeInProgress &&
                            <svg
                                data-tip="Saved to Google Drive"
                                data-place="bottom"
                                xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                <path d="M 16.76 16.852 L 4.188 16.852 C 1.875 16.848 0.002 14.973 0 12.66 L 0 12.524 C 0.004 10.336 1.687 8.517 3.868 8.344 C 4.539 5.304 7.235 3.14 10.348 3.144 L 10.4 3.144 C 14.066 3.153 17.034 6.126 17.036 9.792 L 17.036 10.196 C 18.713 10.341 20 11.745 20 13.428 L 20 13.6 C 20.003 14.461 19.663 15.289 19.055 15.899 C 18.447 16.509 17.621 16.852 16.76 16.852 Z M 10.084 16.1 L 16.76 16.1 C 18.139 16.096 19.256 14.979 19.26 13.6 L 19.26 13.416 C 19.258 12.036 18.14 10.916 16.76 10.912 L 16.208 10.912 L 16.256 10.512 C 16.282 10.288 16.296 10.062 16.296 9.836 L 16.296 9.792 C 16.292 6.536 13.656 3.897 10.4 3.888 L 10.356 3.888 C 7.49 3.883 5.037 5.944 4.548 8.768 L 4.492 9.072 L 4.184 9.072 C 2.285 9.081 0.75 10.621 0.748 12.52 L 0.748 12.656 C 0.75 14.56 2.292 16.104 4.196 16.108 L 9.996 16.108 Z" fill="rgb(217, 217, 217)"></path>
                            </svg>}
                            <input placeholder="untitled" data-place="bottom" value={store.persistStore.fileName} onChange={e => store.persistStore.setFileName(e.target.value)} />
                        </div>

                        <div className="menu-items hz-tb">
                            <button className="ss-button sq" onClick={() => store.app.setSettingsModal(true)}>
                                <i data-tip="Settings" data-place="bottom" className="retina-theessentials-2592" />
                            </button>

                            <button className="ss-button sq" onClick={() => store.app.setLoadModal(true)}>
                                <i data-tip="Load File" data-place="bottom"  className="retina-filetypecontent-1074" />
                            </button>

                            <button className="ss-button sq" onClick={() => store.app.setCopyModal(true)}>
                                <i data-tip="Copy Scenario" data-place="bottom" className="retina-theessentials-2550" />
                            </button>

                            <button className="ss-button sq" onClick={() => store.app.setSpreadsheetPasteModal(true)}>
                                <i data-tip="Generate from Table" data-place="bottom"  className="retina-design-0809" />
                            </button>

                            <button className="ss-button sq" onClick={() => store.app.setExportModal(true)}>
                                <i data-tip="Export" data-place="bottom" className="retina-theessentials-2538" />
                            </button>

                            <button
                                className="ss-button sq active-users-button"
                                onMouseEnter={() => this.setState({ showActiveUsers: true })}
                                onMouseLeave={() => this.setState({ showActiveUsers: false })}
                            >
                                <i className={`retina-communication-0655 ${userCount > 1 ? 'active':''}`} />
                                {userCount > 1 && (
                                    <span className="user-count">!</span>
                                )}
                            </button>
                        </div>
                        <ReactTooltip />
                        {this.state.showActiveUsers && <ActiveUsers store={store} />}
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuBar;
