export default {
    apiKey: "AIzaSyAgDZY07mUD9wQ-TzciyHbo7faIAlw1a9k",
    authDomain: "programming-tool-584cc.firebaseapp.com",
    apiURL: "https://us-central1-programming-tool-584cc.cloudfunctions.net",
    databaseURL: "https://programming-tool-584cc.firebaseio.com",
    projectId: "programming-tool-584cc",
    storageBucket: "programming-tool-584cc.appspot.com",
    messagingSenderId: "749010788233",
    appId: "1:749010788233:web:9e95a563bab83be0898b58",
    measurementId: "G-Q7L78ECQLJ",
    clientId: "749010788233-cesv47ka3oa3mrv1hgparredkoeani71.apps.googleusercontent.com",
};
