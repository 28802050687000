import config from './config';

const SIXTY_MINUTES = 60 * 60 * 1000;


export default class GooglePicker {

    constructor(store) {
        this.picker = window.picker || null;
        this.oauthToken = window.oauthToken || null;

        this.user = store.user;
        this.pickerApiLoaded = false;

        this.developerKey = config.apiKey;
        this.clientId = config.clientId;
        this.appId = config.appId;
        this.scope = 'https://www.googleapis.com/auth/drive.file';

        if (!window.gapi) {
            this.script = document.createElement('script');
            this.script.src = "https://apis.google.com/js/api.js";
            this.script.addEventListener('load', this.loadPicker.bind(this));
            document.body.appendChild(this.script);
        }
        else {
            this.loadPicker();
        }
    }

    dispose() {
        document.body.removeChild(this.script);

        if (this.picker) {
            this.picker.dispose();
        }
    }

    loadPicker() {
        const self = this;

        function authorize() {
            window.gapi.auth2.authorize(
                    {
                        'client_id': self.clientId,
                        'scope': self.scope,
                    },
                    authResult => {
                        if (authResult && !authResult.error) {
                            self.oauthToken = window.oauthToken = authResult.access_token;
                            self.createPicker();
                        }
                    }
            );
        }

        if (!this.oauthToken) {
            gapi.load('auth2', { callback: authorize });
        }

        function load() {
            self.pickerApiLoaded = true;
            self.createPicker();
        }

        if (!window.gapi.picker) {
            gapi.load('picker', { callback: load });
        }
        else {
            self.pickerApiLoaded = true;
        }

        self.createPicker();
    }

    createPicker() {
        const self = this;

        if (this.pickerApiLoaded && this.oauthToken) {
            const view = new google.picker.View(google.picker.ViewId.DOCS);
            //view.setMimeTypes("image/png,image/jpeg,image/jpg");

            if (!this.picker) {
                this.picker = window.picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.NAV_HIDDEN)
                    .setAppId(this.appId)
                    .setOAuthToken(this.oauthToken)
                    .addView(view)
                    .addView(new google.picker.DocsUploadView())
                    .setDeveloperKey(this.developerKey)
                    .setCallback(data => {
                        if (data.action == window.google.picker.Action.PICKED) {
                            if (store.app.introModal) {
                                store.app.setIntroModal(false);
                            }

                            store.fbModel.loadDoc(store.persistStore, store.persistStore.user, {
                                action: 'open',
                                ids: [data.docs[0].id],
                            });

                            self.dispose();
                        }
                    })
                    .build();
            }

            this.picker.setVisible(true);
        }
    }

}
