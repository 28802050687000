import React, {Component, forwardRef, Fragment, useImperativeHandle, useRef} from 'react';
import {inject, observer, Provider} from "mobx-react";
import {AgGridReact} from 'ag-grid-react';
import {reaction} from "mobx";
import Autocomplete from "./Autocomplete";
import NumberFormat, {NumberFormatValues} from "react-number-format";

const ENABLED_KEYS = [
    9,  // TAB
    13, // ENTER
    27, // ESC
    37, // ARROW LEFT
    38, // ARROW UP
    39, // ARROW RIGHT
    40, // ARROW DOWN
];

const DISABLED_KEYS = Array.from(Array(256).keys()).filter(k => !ENABLED_KEYS.includes(k))


@observer
class TableCell extends React.Component {
    render() {
        const {store, data, parentDepartment, customFormat} = this.props;
        const format = (v) => {
            if (!customFormat) return v;
            return customFormat(v);
        };
        const record = store.getRecordByID(data.id)[0];
        let className = 'TableCell ';
        let colorStyle = {};
        if (record) {
            className += (record.isActive ? ' active ' : '') + (record.isSelected ? ' selected' : '');
        }

        let value = this.props.value;
        if (parentDepartment && this.props.value) {
            let parentDepartmentObject = this.props.value;
            value = parentDepartmentObject.name;
            colorStyle = {backgroundColor: parentDepartmentObject.color};
        }
        return (
            <div className={className}>
                {value && <span style={colorStyle}>
                    {format(value)}
                 </span>}
            </div>);
    }
}

@observer
class TableCellEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
        this.inputRef = React.createRef();
    }

    onChange = (text) => {
        this.setState({
            value: text
        })
    };

    getValue() {
        return this.state.value;
    }

    afterGuiAttached() {
        this.inputRef.current.focus();
        this.inputRef.current.select();
    }

    render() {
        return (
            <Autocomplete
                type={"text"}
                ref={this.inputRef}
                defaultValue={this.state.value}
                onSuggestionSelection={this.onChange}
                onInputChange={this.onChange}
                rootStore={this.props.data.rootStore}
                suggestions={this.props.data.rootStore.uniqueTableValues(this.props.colDef.field)}
            />
        )
    }
}

@observer
class TableCellCategoryEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ((props.value && props.value.name) ? props.value.name : props.value),
        };
        this.inputRef = React.createRef();
    }

    onChange = (text) => {
        this.setState({
            value: text
        })
    };

    getValue() {
        return this.state.value;
    }

    afterGuiAttached() {
        this.inputRef.current.focus();
        this.inputRef.current.select();
    }

    render() {
        return (
            <Autocomplete
                type={"text"}
                ref={this.inputRef}
                defaultValue={this.state.value}
                onSuggestionSelection={this.onChange}
                onInputChange={this.onChange}
                rootStore={this.props.data.rootStore}
                parentDepartment={true}
                suggestions={this.props.data.rootStore.uniqueTableValues(this.props.colDef.field)}
            />
        )
    }
}

@observer
class TableCellNumericalEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
        this.inputRef = React.createRef();
    }

    onChange = (e) => {
        this.setState({
            value: e.floatValue
        })
    };

    getValue() {
        return this.state.value;
    }

    afterGuiAttached() {
        this.inputRef.focus();
        this.inputRef.select();
    }

    render() {
        return (
            <NumberFormat
                getInputRef={(el) => this.inputRef = el}
                defaultValue={Math.round(this.props.value * 100) / 100}
                thousandSeparator={true}
                onValueChange={this.onChange}
            />
        )
    }
}


@observer
class TabularView extends Component {
    constructor(props) {
        super(props);
        const {store} = this.props;

        this.state = {
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                flex: 1,
                resizable: true

            },
        };

        this.oldLength = 0;
        reaction(
            () => this.props.store.unitsStore.activeUnits,
            ({length}) => {
                this.gridApi.refreshHeader();
            }
        );

        const watchString = (r) => {
            let p = '';
            if (r.parentDepartment) {
                p = r.parentDepartment.id;
            }
            return `${store.app.tableUpdatesDisabled}_${p}_${r.squareUnits}_${r.squareM}_${r.targetGrossUnits}_${r.name}`;
        };


        reaction(
            () => store.records.map(r => watchString(r)),
            ({length}) => {
                if (store.app.tableUpdatesDisabled) {
                    return;
                }
                if (this.gridApi) {
                    // The ag-grid library claims to do diffing but I can't get it working to
                    // satisfy our uses
                    if (length === this.oldLength) {
                        this.gridApi.updateRowData(store.records.filter(r => !!r));
                    } else {
                        this.gridApi.setRowData(store.records.filter(r => !!r));
                        this.oldLength = length;
                    }
                    this.gridApi.sizeColumnsToFit();
                }
            }
        )
    }

    getColumnDefs() {
        const {store} = this.props;

        const actualCellStyle = {background: '#f5f5f5'};
        const targetCellStyle = {background: '#ececec'};

        return [{
            headerName: "",
            children: [
                {
                    headerName: "Name",
                    field: "name",
                    editable: true,
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRendererFramework: (d) => <TableCell store={store} {...d} />,
                    cellEditorFramework: TableCellEditor,
                    valueSetter: this.valueSetter
                },
                {
                    headerName: "Category",
                    field: "parentDepartment",
                    editable: true,
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRendererFramework: (d) => <TableCell store={store} parentDepartment {...d} />,
                    cellEditorFramework: TableCellCategoryEditor,
                    valueSetter: this.valueSetter
                }
            ]

        }, {
            headerName: "Drawn Size",
            children: [
                {
                    headerName: "Length",
                    field: "planHeight",
                    width: 100,
                    editable: true,
                    sortable: true,
                    cellStyle: actualCellStyle,
                    cellRendererFramework: (d) => <TableCell customFormat={store.unitsStore.formatLinear}
                                                             store={store} {...d} />,
                    cellEditorFramework: TableCellNumericalEditor,
                    valueSetter: this.sizeSetter
                }, {
                    headerName: "Width",
                    field: "planWidth",
                    width: 100,
                    editable: true,
                    sortable: true,
                    cellStyle: actualCellStyle,
                    cellRendererFramework: (d) => <TableCell customFormat={store.unitsStore.formatLinear}
                                                             store={store} {...d} />,
                    cellEditorFramework: TableCellNumericalEditor,
                    valueSetter: this.sizeSetter
                },
                {
                    headerName: "Area",
                    field: "squareUnits",
                    width: 100,
                    // autoHeight: true, can cause performance issues
                    editable: true,
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellStyle: actualCellStyle,
                    cellRendererFramework: (d) => <TableCell customFormat={store.unitsStore.formatArea}
                                                             store={store} {...d} />,
                    cellEditorFramework: TableCellNumericalEditor,
                    valueSetter: this.valueSetterInt
                }]
        }, {
            headerName: "Target",
            children: [{
                headerName: "Net",
                field: "targetNetUnits",
                width: 90,
                editable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                cellStyle: targetCellStyle,
                cellRendererFramework: (d) => <TableCell customFormat={store.unitsStore.formatArea}
                                                         store={store} {...d} />,
                cellEditorFramework: TableCellNumericalEditor,
                valueSetter: this.valueSetterInt
            }, {
                headerName: "Gross",
                field: "targetGrossUnits",
                width: 90,
                editable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                cellStyle: targetCellStyle,
                cellRendererFramework: (d) => <TableCell customFormat={store.unitsStore.formatArea}
                                                         store={store} {...d} />,
                cellEditorFramework: TableCellNumericalEditor,
                valueSetter: this.valueSetterInt
            }, {
                headerName: "%",
                field: "efficiencyFactor",
                width: 75,
                editable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                cellStyle: targetCellStyle,
                cellRendererFramework: (d) => <TableCell customFormat={(v) => `${Math.round(v)}%`}
                                                         store={store} {...d} />,
                cellEditorFramework: TableCellNumericalEditor,
                valueSetter: this.valueSetterInt
            },]
        },
            {
                headerName: "",
                children: [
                    {
                        headerValueGetter: (params) => {
                            return store.unitsStore.getDisplayText("$/unit")
                        },
                        field: "costPerSqUnit",
                        width: 100,
                        editable: true,
                        sortable: true,
                        sortingOrder: ['asc', 'desc'],
                        cellRendererFramework: (d) => <TableCell customFormat={(v) => `$${Math.round(v)}`}
                                                                 store={store} {...d} />,
                        cellEditorFramework: TableCellNumericalEditor,
                        valueSetter: this.valueSetterInt
                    }, {
                        headerName: "Comment",
                        field: "comment",
                        autoHeight: true,// can cause performance issues, but crashes without this...?
                        editable: true,
                        sortable: true,
                        cellRendererFramework: (d) => <TableCell store={store} {...d} />,
                        cellEditorFramework: TableCellEditor,
                        valueSetter: this.valueSetter
                    }
                ]
            }
        ];
    }

    componentDidUpdate() {
        if (this.gridApi) {
            setTimeout(this.gridApi.sizeColumnsToFit.bind(this.gridApi), 200);
        }
    }

    valueSetterInt = (params) => {
        let record = this.props.store.getRecordByID(params.data.id)[0];
        record.updateMetadata(params.column.colId, parseInt(params.newValue));
    };

    valueSetter = (params) => {
        let record = this.props.store.getRecordByID(params.data.id)[0];
        record.updateMetadata(params.column.colId, params.newValue);
        this.gridApi.resetRowHeights();
    };

    sizeSetter = (params) => {
        let record = this.props.store.getRecordByID(params.data.id)[0];
        if (params.column.colId === 'planWidth') {
            record.setPlanWidth(parseFloat(params.newValue));
        }
        if (params.column.colId === 'planHeight') {
            record.setPlanHeight(parseFloat(params.newValue));
        }
    };

    onGridReady = (params) => {
        const {store} = this.props;
        this.gridApi = params.api;
        store.setGridApi(this.gridApi);
        this.gridApi.sizeColumnsToFit();
    };

    onCellMouseOver = (params) => {
        let record = this.props.store.getRecordByID(params.data.id)[0];
        record.setActive(true);
    };
    onCellMouseOut = (params) => {
        let record = this.props.store.getRecordByID(params.data.id)[0];
        if (record) record.setActive(false);
    };

    onRowSelected = (params) => {
        if (this.props.store.filterBy !== 'selectedRecords' && !this.rowSelectDebounce) {
            this.rowSelectDebounce = setTimeout(() => {
                let record = this.props.store.getRecordByID(params.data.id)[0];
                if (!params.event.ctrlKey) {
                    this.props.store.clearSelected();
                }
                this.props.store.setSelected(record);
                this.rowSelectDebounce = null;
            }, 200);
        }
    };

    resetRowHeights = () => {
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.resetRowHeights()
            }, 0)
        }
    }

    onRowDoubleClicked = () => {
        if (this.rowSelectDebounce) {
            clearTimeout(this.rowSelectDebounce);
            this.rowSelectDebounce = null;
        }
    };

    getNumericCellEditor() {
        // https://www.ag-grid.com/javascript-grid-cell-editing/
        function isCharNumeric(charStr) {
            return !!/\d/.test(charStr);
        }

        function isKeyPressedNumeric(event) {
            var charCode = getCharCodeFromEvent(event);
            var charStr = String.fromCharCode(charCode);
            return isCharNumeric(charStr);
        }

        function getCharCodeFromEvent(event) {
            event = event || window.event;
            return typeof event.which === 'undefined' ? event.keyCode : event.which;
        }

        // function to act as a class
        function NumericCellEditor() {
        }

        // gets called once before the renderer is used
        NumericCellEditor.prototype.init = function (params) {
            // we only want to highlight this cell if it started the edit, it is possible
            // another cell in this row started the edit
            this.focusAfterAttached = params.cellStartedEdit;

            // create the cell
            this.eInput = document.createElement('input');
            this.eInput.style.width = '100%';
            this.eInput.style.height = '100%';
            this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;

            var that = this;
            this.eInput.addEventListener('keypress', function (event) {
                if (!isKeyPressedNumeric(event)) {
                    that.eInput.focus();
                    if (event.preventDefault) event.preventDefault();
                }
            });
        };

        // gets called once when grid ready to insert the element
        NumericCellEditor.prototype.getGui = function () {
            return this.eInput;
        };

        // focus and select can be done after the gui is attached
        NumericCellEditor.prototype.afterGuiAttached = function () {
            // only focus after attached if this cell started the edit
            if (this.focusAfterAttached) {
                this.eInput.focus();
                this.eInput.select();
            }
        };

        // returns the new value after editing
        NumericCellEditor.prototype.isCancelBeforeStart = function () {
            return this.cancelBeforeStart;
        };

        // returns the new value after editing
        NumericCellEditor.prototype.getValue = function () {
            return this.eInput.value;
        };

        // when we tab onto this editor, we want to focus the contents
        NumericCellEditor.prototype.focusIn = function () {
            var eInput = this.getGui();
            eInput.focus();
            eInput.select();
        };

        return NumericCellEditor;
    }

    render() {
        const {store} = this.props;

        return (
            <div
                className={"TabularView " + (this.props.expanded ? "opened" : "closed") + (this.props.fullSize ? " full" : "")}>
                <div>
                    <div className="header">
                        <button onClick={this.props.toggleExpand}>
                            <i className="retina-design-0730"/>
                        </button>
                        <h5>TABULAR</h5>
                        <div className="tabs">
                            <div className="tab">
                                <p
                                    className={`p-md ${!store.isFiltering ? 'active' : ''}`}
                                    onClick={() => store.setFilterBy('')}
                                >
                                    All Items
                                </p>
                                <p
                                    className={`p-md ${store.filterBy === 'selectedRecords' ? 'active' : ''}`}
                                    onClick={() => store.setFilterBy('selectedRecords')}
                                >
                                    Selected
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        id="myGrid"
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                        className="ag-theme-alpine">

                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            components={{
                                numericCellEditor: this.getNumericCellEditor()
                            }}
                            rowData={store.isFiltering ? store[store.filterBy] : store.records}
                            rowHeight={40}
                            onRowDataChanged={this.resetRowHeights}
                            onRowClicked={this.onRowSelected}
                            onRowDoubleClicked={this.onRowDoubleClicked}
                            onCellMouseOver={this.onCellMouseOver}
                            onCellMouseOut={this.onCellMouseOut}
                            suppressKeyboardEvent={({event}) => DISABLED_KEYS.includes(event.which)}
                            enterMovesDownAfterEdit={true}
                            onGridReady={this.onGridReady}
                            localeText={{noRowsToShow: store.isFiltering ? 'No elements selected' : 'To add an element, use the shape tool in the top left toolbar'}}
                            stopEditingWhenGridLosesFocus={false}>
                        </AgGridReact>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabularView;
