import React, {Component} from 'react';
import {observer} from "mobx-react";

@observer
class Autocomplete extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeSuggestions: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: this.props.defaultValue
        }
    }

    onChange = (e) => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        filteredSuggestions.unshift(null);

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
        this.props.onInputChange(e.currentTarget.value);
    };

    onClick = (e) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        }, this.onSuggestionSelection);
    };

    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) { // Enter key
            if (filteredSuggestions[activeSuggestion]){
                this.setState({
                    activeSuggestion: 0,
                    showSuggestions: false,
                    userInput: filteredSuggestions[activeSuggestion]
                }, this.onSuggestionSelection);
            } else {
                this.onSuggestionSelection();
            }
        } else if (e.keyCode === 38) { // Up arrow
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({
                activeSuggestion: activeSuggestion - 1,
                userInput: filteredSuggestions[activeSuggestion - 1]
            }, this.onSuggestionSelection);
        } else if (e.keyCode === 40) { // Down arrow
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({
                activeSuggestion: activeSuggestion + 1,
                userInput: filteredSuggestions[activeSuggestion + 1]
            }, this.onSuggestionSelection);
        }
    };

    onSuggestionSelection = () => {
        this.props.onSuggestionSelection(this.state.userInput);
    };

    render() {
        let suggestionsListComponent;
        if (this.state.showSuggestions && this.state.userInput) {
            if (this.state.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {this.state.filteredSuggestions.map((suggestion, index) => {
                            if (suggestion){
                                let className;

                                // Flag the active suggestion with a class
                                if (index === this.state.activeSuggestion) {
                                    className = "suggestion-active";
                                }

                                let style = {};
                                if(this.props.parentDepartment){
                                    let dept = this.props.rootStore.persistStore.parentDepartments.filter(dept => dept.name === suggestion);
                                    if (dept.length > 0){
                                        style = {backgroundColor: dept[0].color}
                                    }
                                }

                                return (
                                    <li
                                        className={className}
                                        key={suggestion}
                                        tabIndex={index}
                                        style={style}
                                        onClick={this.onClick}
                                    >
                                        {suggestion}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                );
            }
        }

        return (
            <div className={"Autocomplete"}>
                <input
                    autoFocus
                    ref={this.props.inputRef}
                    type={this.props.type || 'text'}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    value={this.state.userInput}
                />
                {suggestionsListComponent}
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <Autocomplete inputRef={ref} {...props} />);
